<template>
    <div class="tabbar">
        <div class="itembar" @click="tabClick(item.path)" v-for="item in typeList" :key="item.id">
            <img :src="item.path == $route.path? item.activeImg:item.img" :class="item.id == 3? 'imgbig': ''">
            <span :class="{'textColor': item.path == $route.path}">{{ item.label }}</span>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return {}
        },
        computed: {
            typeList() {
                return [
                    {
                        path: '/index/centpage',
                        img: require('@/components/tabBarIcon/home-hui.png'),
                        activeImg: require('@/components/tabBarIcon/home.png'),
                        label: this.$t('button.button.home'),
                        id: 1
                    },
                    {
                        path: '/index/allcategories',
                        img: require('@/components/tabBarIcon/fenlei-hui.png'),
                        activeImg: require('@/components/tabBarIcon/fenlei.png'),
                        label: this.$t('button.button.categories'),
                        id: 2
                    },
                    {
                        path: '/index/cart/mycart',
                        img: require('@/components/tabBarIcon/cart-big.png'),
                        activeImg: require('@/components/tabBarIcon/cart-big.png'),
                        label: this.$t('button.button.cart'),
                        id: 3
                    },
                    {
                        path: '/index/mypages',
                        img: require('@/components/tabBarIcon/my-hui.png'),
                        activeImg: require('@/components/tabBarIcon/my.png'),
                        label: this.$t('button.button.my'),
                        id: 4
                    },
                    {
                        path: '/index/mypage/conversations',
                        img: require('@/components/tabBarIcon/xiaoxi-hui.png'),
                        activeImg: require('@/components/tabBarIcon/xiaoxi.png'),
                        label: this.$t('button.button.notification'),
                        id: 5
                    }
                ]
            }
        },
        methods: {
            tabClick: function (path) {
                this.$router.push({path: path})
                window.scrollTo(0,0)
            },  
        },
    }

</script>


<style scoped lang="less">
    .tabbar {
        width: 100%;
        height: 150px;
        font-size: 32px;
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 99999;
        background: #ffffff;
        .itembar {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 20%;
            flex-shrink: 0;
            img {
                width: 60px;
                height: 60px;
                margin-bottom: 10px;
            }
            .imgbig {
                width: 120px;
                height: 120px;
                margin-top: -60px;
            }
            .textColor{
                color: #00ADF1 !important;
            }
        }
    }
</style>