<template>
    <div class="pc">
        <div class="paybox">
            <div class="leftbox">
                <div class="paytc">
                    <div class="tchezi">
                        <div class="Any">
                            <span>{{ $t('buyer.shoppingInfo.anyAdditionalInfo') }}?</span>
                        </div>
                        <div class="textbox">
                            <textarea name="" id="" cols="30" rows="10" v-model="remarks"></textarea>
                        </div>
                        <div class="paytitle">
                            <span>{{ $t('buyer.shoppingInfo.SelectAPaymentOption') }}</span>
                        </div>
                        <div class="payselectbox">
                            <div v-for="item in peyList" :key="item.id">
                                <div class="payselect" :class="payid == item.id? 'dongtai' : ''" @click="seletcPay(item.id)">
                                    <img :src="item.payment_img" />
                                    <span>{{ item.payment_name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="zhifuma">
                            <div class="adresbox">
                                <span>{{ $t('buyer.myWallet.paymentAddress') }}</span>
                                <span @click="copyText">{{ $t('seller.product.copy') }}</span>
                                <span>{{ payAddress }}</span>
                            </div>
                            <div class="qrcode">
                                <span class="qrtext">{{ $t('seller.makeCryptoPayment.qrCode') }}</span>
                                <div class="ewmbox">
                                    <div class="erweima">
                                        <img :src="qrCode" />
                                    </div>
                                    <div class="addrbox">
                                        <span>{{ $t('buyer.myWallet.paymentAddress') }}</span>
                                        <span>{{ payAddress }}</span>
                                    </div>
                                </div>
                                <div class="miaosu">
                                    <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                                    <input type="text" :placeholder="describe" />
                                </div>
                            </div>
                        </div>
                        <el-divider>
                            <span style="color: #969696;">Or</span>
                        </el-divider>
                        <div class="yourbox">
                            <span>{{ $t('buyer.myWallet.walletBalance') }}:</span>
                            <span>{{ goodsInfo.balance }}</span>
                        </div>
                        <div class="brabox" @click="paySubmit">
                            <span>{{ $t('buyer.myCart.payWithWallet') }}</span>
                        </div>
                    </div>
                </div>
                <div class="dibubox">
                    <div class="leftfanhui">
                        <img src="../../../assets/img/zuojiantou-lan.png">
                        <span>{{ $t('buyer.myCart.returnToShop') }}</span>
                    </div>
                    <!-- <div class="rightbox" @click="toOrder">
                        <span>Complete Order</span>
                    </div> -->
                </div>
            </div>
            <div class="rightbox">
                <div class="titlsbox">
                    <span>{{ $t('buyer.orderDetail.summary') }}</span>
                    <span>{{ list.length }} Items</span>
                </div>
                <div class="itembox">
                    <div class="items">
                        <span>{{ $t('buyer.category.products') }}</span>
                        <span>{{ $t('buyer.orderDetail.price') }}</span>
                    </div>
                    <div class="itemss">
                        <div class="shoplist" v-for="item in goodsInfo.list" :key="item.id">
                            <span>{{ item.goods_name }} * {{ item.count }} </span>
                            <span>{{ item.unit_price }}</span>
                        </div>
                    </div>
                    <div class="items">
                        <span>{{ $t('buyer.orderDetail.subtotal') }}</span>
                        <span>{{ goodsInfo.subtotal }}</span>
                    </div>
                    <div class="items">
                        <span>{{ $t('buyer.orderDetail.tax') }}</span>
                        <span>{{ goodsInfo.tax }}</span>
                    </div>
                    <div class="items">
                        <span>{{ $t('buyer.orderDetail.shipping') }}</span>
                        <span>{{ goodsInfo.total_shipping }}</span>
                    </div>
                    <div class="heji">
                        <span>{{ $t('buyer.orderDetail.subtotal') }}</span>
                        <span>{{ goodsInfo.total }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 手机端 -->
    <div class="phone">
        <div class="paybox">
            <div class="leftbox">
                <div class="paytc">
                    <div class="tchezi">
                        <div class="Any">
                            <span>{{ $t('buyer.shoppingInfo.anyAdditionalInfo') }}?</span>
                        </div>
                        <div class="textbox">
                            <textarea v-model="remarks"></textarea>
                        </div>
                        <div class="paytitle">
                            <span>{{ $t('buyer.shoppingInfo.SelectAPaymentOption') }}</span>
                        </div>
                        <div class="payselectbox">
                            <div v-for="item in peyList" :key="item.id">
                                <div class="payselect" :class="payid == item.id? 'dongtai' : ''" @click="seletcPay(item.id)">
                                    <img :src="item.payment_img" />
                                    <span>{{ item.payment_name }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="zhifuma">
                            <div class="adresbox">
                                <span>{{ $t('buyer.myWallet.paymentAddress') }}</span>
                                <span @click="copyText">{{ $t('seller.product.copy') }}</span>
                                <span>{{ payAddress }}</span>
                            </div>
                            <div class="qrcode">
                                <span class="qrtext">{{ $t('seller.makeCryptoPayment.qrCode') }}</span>
                                <div class="ewmbox">
                                    <div class="erweima">
                                        <img :src="qrCode" />
                                    </div>
                                    <div class="addrbox">
                                        <span>{{ $t('buyer.myWallet.paymentAddress') }}</span>
                                        <span>{{ payAddress }}</span>
                                    </div>
                                </div>
                                <div class="miaosu">
                                    <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                                    <input type="text" :placeholder="describe" />
                                </div>
                            </div>
                        </div>
                        <el-divider>
                            <span style="color: #969696;">Or</span>
                        </el-divider>
                        <div class="yourbox">
                            <span>{{ $t('buyer.myWallet.walletBalance') }}:</span>
                            <span>{{ goodsInfo.balance }}</span>
                        </div>
                        <div class="brabox" @click="paySubmit">
                            <span>{{ $t('buyer.myCart.payWithWallet') }}</span>
                        </div>
                    </div>
                </div>
                <div class="dibubox">
                    <div class="leftfanhui">
                        <img src="../../../assets/img/zuojiantou-lan.png">
                        <span>{{ $t('buyer.myCart.returnToShop') }}</span>
                    </div>
                    <!-- <div class="rightbox" @click="toOrder">
                        <span>Complete Order</span>
                    </div> -->
                </div>
            </div>
            <div class="rightbox">
                <div class="titlsbox">
                    <span>{{ $t('buyer.orderDetail.summary') }}</span>
                    <span>{{ list.length }} Items</span>
                </div>
                <div class="itembox">
                    <div class="items">
                        <span>{{ $t('buyer.category.products') }}</span>
                        <span>{{ $t('buyer.orderDetail.price') }}</span>
                    </div>
                    <div class="itemss">
                        <div class="shoplist" v-for="item in goodsInfo.list" :key="item.id">
                            <span>{{ item.goods_name }} * {{ item.count }} </span>
                            <span>{{ item.unit_price }}</span>
                        </div>
                    </div>
                    <div class="items">
                        <span>{{ $t('buyer.orderDetail.subtotal') }}</span>
                        <span>{{ goodsInfo.subtotal }}</span>
                    </div>
                    <div class="items">
                        <span>{{ $t('buyer.orderDetail.tax') }}</span>
                        <span>{{ goodsInfo.tax }}</span>
                    </div>
                    <div class="items">
                        <span>{{ $t('buyer.orderDetail.shipping') }}</span>
                        <span>{{ goodsInfo.total_shipping }}</span>
                    </div>
                    <div class="heji">
                        <span>{{ $t('buyer.orderDetail.subtotal') }}</span>
                        <span>{{ goodsInfo.total }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {paymentMethod} from '@/api/api'
    import {orderPay,orderSubmit} from '@/api/buyerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data() {
            return {
                peyList: [], //支付方式列表
                payid: '', //选择对比用
                qrCode: '', //二维码
                payAddress: '',//支付地址
                describe: '', //描述
                goodsInfo: {}, //商品信息
                list: [], 
                remarks: '', //订单备注
            }
        },
        mounted(){
            this.getPayMethod()
            this.getPayInfo()
        },
        methods: {
            // 获取支付方式
            async getPayMethod(){
                const {data: res} = await paymentMethod()
                this.peyList = res.success
                this.payid = res.success[0].id
                this.qrCode = res.success[0].qr_code
                this.payAddress = res.success[0].payment_address
                this.describe = res.success[0].describe
                console.log(res)
            },
            // 选择支付方式
            seletcPay(payid){
                this.payid = payid
                for(var i = 0; i < this.peyList.length; i++) {
                    if(payid == this.peyList[i].id) {
                        this.qrCode = this.peyList[i].qr_code
                        this.payAddress = this.peyList[i].payment_address
                        this.describe = this.peyList[i].describe
                    }
                }
            },
            // 获取订单信息
            async getPayInfo(){
                const {data: res} = await orderPay()
                if(res.status == 200) {
                    this.goodsInfo = res.success
                    this.list = res.success.list
                }
                console.log(res)
            },
            // 复制地址
            copyText(){
                this.$copyText(this.payAddress).then((e)=>{
                    ElMessage.success('Copy Success')
                })
            },
            // 去完成订单页
            async paySubmit() {
                const {data: res} = await orderSubmit({
                    payment_method: 1, //支付方式 1余额支付，2ub支付
                    address_id: sessionStorage.getItem('id'), //地址id
                    remarks: this.remarks //备注（Any additional info?） 
                })
                if(res.status == 200) {
                    this.$router.push({path: '/index/cart/completeorder', query: {order_id: res.success.order_id_str}})
                }else {
                    return ElMessage.error(res.message)
                }
            }
        }
    }
</script>


<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation : portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }

    .pc {
        .paybox {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;

            .leftbox {
                .tchezi {
                    width: 780px;
                    overflow: auto;
                    background: #fff;
                    border-radius: 5px;
                    box-sizing: 15px;
                    padding: 0 15px;

                    .Any {
                        width: 100%;
                        height: 47px;
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: 500;
                        color: #000000;
                        line-height: 47px;
                        text-align: left;
                        border-bottom: 1px solid #dcdcdc;
                    }

                    .textbox {
                        width: 100%;
                        height: 130px;
                        margin-top: 25px;

                        textarea {
                            width: 100%;
                            height: 100%;
                            resize: none;
                            outline: none;
                            border: none;
                            border: 1px solid #DCDCDC;
                            border-radius: 5px;
                            box-sizing: border-box;
                            padding: 10px;
                            font-size: 14px;
                        }
                    }

                    .paytitle {
                        width: 100%;
                        height: 47px;
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: 500;
                        color: #000000;
                        line-height: 47px;
                        text-align: left;
                        border-bottom: 1px solid #dcdcdc;
                        margin-top: 20px;
                    }

                    .payselectbox {
                        width: 750px;
                        height: 320px;
                        display: flex;
                        flex-wrap: wrap;
                        box-sizing: border-box;
                        padding-top: 25px;

                        .payselect {
                            width: 150px;
                            height: 120px;
                            border: 1px solid #CACACA;
                            border-radius: 5px;
                            font-size: 12px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #000000;
                            line-height: 30px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin: 0 10px;
                            cursor: pointer;

                            img {
                                width: 100px;
                                height: 60px;
                                margin-top: 10px;
                            }
                        }
                        .dongtai {
                            border: 1px solid #00adf1;
                        }
                    }

                    .zhifuma {
                        width: 780px;
                        border: 1px solid #dcdcdc;
                        border-radius: 5px;
                        box-sizing: border-box;
                        padding: 30px 15px;

                        .adresbox {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 14px;
                            span:nth-child(2) {
                                width: 110px;
                                height: 35px;
                                background: #00adf1;
                                border-radius: 5px;
                                line-height: 35px;
                                color: #fff;
                                cursor: pointer;
                            }

                            span:nth-child(3) {
                                width: 530px;
                                height: 35px;
                                border: 1px solid #dcdcdc;
                                border-radius: 5px;
                                line-height: 35px;
                                box-sizing: border-box;
                                padding-left: 30px;
                                text-align: left;
                                color: #969696;
                            }
                        }

                        .qrcode {
                            margin-top: 35px;

                            .qrtext {
                                display: block;
                                width: 100%;
                                font-size: 14px;
                                font-family: Arial;
                                font-weight: 400;
                                color: #000000;
                                line-height: 30px;
                                text-align: left;
                            }

                            .ewmbox {
                                width: 510px;
                                margin-left: 150px;
                                margin-top: 50px;

                                .erweima {
                                    &>img {
                                        width: 377px;
                                        height: 420px;
                                        border-radius: 15px;
                                        overflow: hidden;
                                        margin-top: 50px;
                                    }
                                }

                                .addrbox {
                                    display: flex;
                                    flex-direction: column;
                                    font-size: 26px;
                                    font-family: Arial;
                                    font-weight: 400;
                                    line-height: 42px;
                                    margin-top: 30px;

                                    span:nth-child(2) {
                                        color: #969696;
                                    }
                                }
                            }

                            .miaosu {
                                display: flex;
                                justify-content: space-between;
                                display: flex;
                                align-items: center;
                                margin-top: 40px;
                                font-size: 14px;

                                input {
                                    width: 530px;
                                    height: 44px;
                                    border: 1px solid #dcdcdc;
                                    border-radius: 5px;
                                    outline: none;
                                    box-sizing: border-box;
                                    padding-left: 20px;
                                }
                            }
                        }
                    }

                    .yourbox {
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;

                        span:nth-child(2) {
                            color: #000000;
                            margin-left: 10px;
                        }
                    }

                    .brabox {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        margin-bottom: 30px;

                        span {
                            display: inline-block;
                            width: 188px;
                            height: 42px;
                            background: #00ADF1;
                            border-radius: 5px;
                            font-size: 14px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                            line-height: 42px;
                            cursor: pointer;
                        }
                    }
                }

                .dibubox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 30px;
                    margin-top: 30px;

                    .leftfanhui {
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #00ADF1;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    // .rightbox {
                    //     width: 190px;
                    //     height: 42px;
                    //     background: #00ADF1;
                    //     border-radius: 5px;
                    //     font-size: 14px;
                    //     font-family: Arial;
                    //     font-weight: 400;
                    //     color: #FFFFFF;
                    //     text-align: center;
                    //     line-height: 42px;
                    //     cursor: pointer;
                    // }
                }
            }

            .rightbox {
                width: 500px;

                .titlsbox {
                    width: 100%;
                    height: 60px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: solid 1px #CACACA;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    justify-content: space-between;
                    background: #fff;

                    span:nth-child(1) {
                        margin-top: 15px;
                    }

                    span:nth-child(2) {
                        width: 80px;
                        height: 35px;
                        background: #00ADF1;
                        border: 1px solid #DCDCDC;
                        border-radius: 5px;
                        color: #FFFEFE;
                        line-height: 35px;
                        text-align: center;
                    }
                }

                .itembox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 1px 30px 30px 30px;
                    background: #fff;
                    .itemss {
                        width: 100%;
                        border: 1px solid #DCDCDC;
                        border-radius: 4px;
                        box-sizing: border-box;
                        padding: 0 20px;
                        min-height: 50px;
                        font-size: 14px;
                        font-weight: 600;
                        margin-top: 15px;
                        .shoplist {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            span:nth-child(1) {
                                display: inline-block;
                                width: 78%;
                                height: 30px;
                                overflow: hidden;
                                line-height: 30px;
                                text-align: left;
                                white-space:nowrap ;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    .items {
                        width: 100%;
                        border: 1px solid #DCDCDC;
                        border-radius: 4px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0 20px;
                        min-height: 50px;
                        font-size: 14px;
                        font-weight: 600;
                        margin-top: 15px;

                        span:nth-child(1) {
                            width: 78%;
                            line-height: 20px;
                            text-align: left;
                        }
                    }

                    .heji {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0 20px;
                        font-size: 14px;
                        font-weight: 600;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
    .phone {
        .paybox {
            display: flex; 
            flex-direction: column;
            justify-content: space-between;
            margin-top: 30px;
            .leftbox {
                width: 100%;
                .tchezi {
                    width: 100%;
                    overflow: auto;
                    background: #fff;
                    border-radius: 5px;
                    box-sizing: 15px;
                    padding: 0 15px;

                    .Any {
                        width: 100%;
                        height: 60px;
                        font-size: 28px;
                        font-family: Arial;
                        font-weight: 600;
                        color: #000000;
                        line-height: 60px;
                        text-align: left;
                        border-bottom: 2px solid #dcdcdc;
                    }

                    .textbox {
                        width: 100%;
                        height: 200px;
                        margin-top: 25px;

                        textarea {
                            width: 100%;
                            height: 100%;
                            resize: none;
                            outline: none;
                            border: none;
                            border: 2px solid #DCDCDC;
                            border-radius: 5px;
                            box-sizing: border-box;
                            padding: 10px;
                            font-size: 14px !important;
                        }
                    }

                    .paytitle {
                        width: 100%;
                        height: 60px;
                        font-size: 28px;
                        font-family: Arial;
                        font-weight: 600;
                        color: #000000;
                        line-height: 60px;
                        text-align: left;
                        border-bottom: 2px solid #dcdcdc;
                        margin-top: 20px;
                    }

                    .payselectbox {
                        width: 100%;
                        min-height: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        box-sizing: border-box;
                        padding-top: 25px;
                        .payselect {
                            width: 220px;
                            height: 200px;
                            flex: auto;
                            border: 2px solid #CACACA;
                            border-radius: 5px;
                            font-size: 24px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #000000;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            img {
                                width: 180px;
                                height: 100px;
                            }
                        }
                        .dongtai {
                            border: 2px solid #00adf1;
                        }
                    }

                    .zhifuma {
                        width: 100%;
                        border: 2px solid #dcdcdc;
                        border-radius: 5px;
                        box-sizing: border-box;
                        padding: 30px 15px;
                        margin-top: 20px;
                        .adresbox {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 26px;
                            span:nth-child(2) {
                                padding: 3px 10px;
                                background: #00adf1;
                                border-radius: 5px;
                                line-height: 35px;
                                color: #fff;
                                cursor: pointer;
                            }

                            span:nth-child(3) {
                                min-width: 300px;
                                height: 35px;
                                border: 2px solid #dcdcdc;
                                border-radius: 5px;
                                line-height: 35px;
                                box-sizing: border-box;
                                padding-left: 10px;
                                text-align: left;
                                color: #969696;
                            }
                        }

                        .qrcode {
                            margin-top: 35px;

                            .qrtext {
                                display: block;
                                width: 100%;
                                font-size: 26px;
                                font-family: Arial;
                                font-weight: 400;
                                color: #000000;
                                line-height: 30px;
                                text-align: left;
                            }

                            .ewmbox {
                                width: 100%;
                                margin-top: 50px;
                                .erweima {
                                    &>img {
                                        width: 60%;
                                        border-radius: 15px;
                                        overflow: hidden;
                                        margin-top: 50px;
                                    }
                                }

                                .addrbox {
                                    display: flex;
                                    flex-direction: column;
                                    font-size: 26px;
                                    font-family: Arial;
                                    font-weight: 400;
                                    line-height: 42px;
                                    margin-top: 30px;

                                    span:nth-child(2) {
                                        color: #969696;
                                    }
                                }
                            }

                            .miaosu {
                                display: flex;
                                justify-content: space-between;
                                display: flex;
                                align-items: center;
                                margin-top: 40px;
                                font-size: 26px;

                                input {
                                    width: 80%;
                                    height: 80px;
                                    border: 2px solid #dcdcdc;
                                    border-radius: 5px;
                                    outline: none;
                                    box-sizing: border-box;
                                    padding-left: 20px;
                                    font-size: 26px;
                                }
                            }
                        }
                    }

                    .yourbox {
                        font-size: 28px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;

                        span:nth-child(2) {
                            color: #000000;
                            margin-left: 10px;
                        }
                    }

                    .brabox {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        margin-bottom: 30px;

                        span {
                            display: inline-block;
                            width: 300px;
                            height: 80px;
                            background: #00ADF1;
                            border-radius: 5px;
                            font-size: 26px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #FFFFFF;
                            text-align: center;
                            line-height: 80px;
                        }
                    }
                }

                .dibubox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100px;
                    .leftfanhui {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #00ADF1;
                        display: flex;
                        align-items: center;

                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }

            .rightbox {
                width: 100%;

                .titlsbox {
                    width: 100%;
                    height: 80px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: solid 1px #CACACA;
                    font-size: 26px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    justify-content: space-between;
                    background: #fff;

                    span:nth-child(1) {
                        margin-top: 15px;
                    }

                    span:nth-child(2) {
                        width: 80px;
                        height: 35px;
                        background: #00ADF1;
                        border: 1px solid #DCDCDC;
                        border-radius: 5px;
                        color: #FFFEFE;
                        line-height: 35px;
                        text-align: center;
                    }
                }

                .itembox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 1px 30px 30px 30px;
                    background: #fff;
                    .itemss {
                        width: 100%;
                        border: 2px solid #DCDCDC;
                        border-radius: 5px;
                        box-sizing: border-box;
                        padding: 0 20px;
                        min-height: 80px;
                        font-size: 24px;
                        font-weight: 600;
                        margin-top: 15px;
                        .shoplist {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            span:nth-child(1) {
                                display: inline-block;
                                width: 78%;
                                height: 40px;
                                overflow: hidden;
                                line-height: 40px;
                                text-align: left;
                                white-space:nowrap ;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    .items {
                        width: 100%;
                        height: 80px;
                        border: 2px solid #DCDCDC;
                        border-radius: 4px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0 20px;
                        min-height: 50px;
                        font-size: 26px;
                        font-weight: 600;
                        margin-top: 15px;

                        span:nth-child(1) {
                            width: 78%;
                            line-height: 20px;
                            text-align: left;
                        }
                    }

                    .heji {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0 20px;
                        font-size: 26px;
                        font-weight: 600;
                        margin-top: 30px;
                    }
                }
            }
        }
    }

</style>