<template>
    <div class="pc">
        <div class="bigbox">
            <div class="leftbox">
                <div class="catebox">
                    <div class="cate">
                        <span>{{ $t('buyer.category.category') }}</span>
                    </div>
                    <div class="womenbox">
                        <div v-for="item in cateList" :key="item.cate_id">
                            <div class="items" @click="clickChange(item.cate_id)">
                                <span>{{ item.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="huakuai">
                    <span>Price range</span>
                    <div class="huak">
                        <el-slider v-model="hkValue" :min="1" :max="100000" range @change="sliderChange"/>
                    </div>
                    <div class="zhibox">
                        <span>1.00</span>
                        <span>100000.00</span>
                    </div>
                </div>
            </div>
            <div class="rightbox">
                <div class="titlebox">
                    <div class="selectbox">
                        <div class="selectitem">
                            <span>{{ $t('buyer.category.brands') }}</span>
                            <el-select v-model="brandValue" class="m-2" placeholder="Select" size="large" @change="brandChange">
                                <el-option v-for="item in brandList" :key="item.goods_brand_id" :label="item.name" :value="item.goods_brand_id" />
                            </el-select>
                        </div>
                        <div class="selectitem">
                            <span>{{ $t('buyer.category.sortBy') }}</span>
                            <el-select v-model="orderbyValue" class="m-2" placeholder="Select" size="large" @change="orderbyChange">
                                <el-option v-for="item in orderbyList" :key="item.value" :label="item.order_by_name" :value="item.value" />
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="shopbox">
                    <div class="xunhuan">
                        <div class="itemshop" v-for="item in goodsList" :key="item.id">
                            <img :src="item.main_img" @click="toDetails(item.id)" />
                            <div class="wenzihe">
                                <span>{{ item.primary }}</span>
                                <el-rate v-model="xingxing" disabled />
                                <div class="miaosu">
                                    <span>{{ item.goods_name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fenye">
                        <el-pagination 
                            background 
                            layout="prev, pager, next" 
                            :total="count" 
                            :default-page-size="12"
                            @prev-click="prevChange"
                            @next-click="nextChange"
                            @current-change="currentChaneg"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="bigbox">
            <!-- <div class="huakuai">
                <span>Price range</span>
                <div class="huak">
                    <el-slider v-model="hkValue" :min="1" :max="100000" range @change="sliderChange"/>
                </div>
                <div class="zhibox">
                    <span>1.00</span>
                    <span>100000.00</span>
                </div>
            </div> -->
            <div class="rightbox">
                <div class="titlebox">
                    <div class="selectbox">
                        <div class="selectitem">
                            <span>{{ $t('buyer.category.brands') }}</span>
                            <el-select v-model="brandValue" class="m-2" placeholder="Select" size="small" @change="brandChange">
                                <el-option v-for="item in brandList" :key="item.goods_brand_id" :label="item.name" :value="item.goods_brand_id" style="font-size: 24px;height: 60px;"/>
                            </el-select>
                        </div>
                        <div class="selectitem">
                            <span>{{ $t('buyer.category.sortBy') }}</span>
                            <el-select v-model="orderbyValue" class="m-2" placeholder="Select" size="small" @change="orderbyChange">
                                <el-option v-for="item in orderbyList" :key="item.value" :label="item.order_by_name" :value="item.value" style="font-size: 24px;height: 60px;"/>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="shopbox">
                    <div class="xunhuan">
                        <div class="itemshop" v-for="item in goodsList" :key="item.id">
                            <div class="itemimg">
                                <img :src="item.main_img" @click="toDetails(item.id)" />
                            </div>
                            <div class="wenzihe">
                                <span>{{ item.primary }}</span>
                                <el-rate v-model="xingxing" disabled />
                                <div class="miaosu">
                                    <span>{{ item.goods_name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fenye">
                        <el-pagination
                            background 
                            layout="prev, pager, next" 
                            :total="count" 
                            :default-page-size="12"
                            @prev-click="prevChange"
                            @next-click="nextChange"
                            @current-change="currentChaneg"
                            class="my-pagination"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


  <script>
    import {topBrand,goodsBy,sysGoods,pCate} from '@/api/buyerapi' 
    export default {
        data() {
            return {
                page: 1, //页码
                count: 0, //总条数
                hkValue: 1,//滑块的值
                xingxing: 2, //星星数量
                brandList: [], //品牌列表
                orderbyList: [], //分类排序列表
                brandValue: '', //选择的品牌的值
                orderbyValue: '', //选择的分类排序值
                orderbyId: '0', //排序id
                goodsList: [], //商品列表
                priceStart: 0,
                priceEnd: 0,
                cateList: [], //左侧分类
                cateId: '0', //左侧分类ID
            }
        },
        mounted(){
            this.getBrand()
            this.getOrderBy()
            this.getsysGoods()
            this.getCate()
        },
        methods: {
            // 获取品牌分类
            async getBrand(){
                const {data:res} = await topBrand()
                if(res.status == 200) {
                    console.log('品牌分类',res)
                    this.brandList = res.success
                }
            },
            // 商品分类排序
            async getOrderBy(){
                const {data:res} = await goodsBy()
                if(res.status == 200) {
                    console.log('排序',res)
                    this.orderbyList = res.success
                }
            },
            // 商品分类列表
            async getsysGoods(){
                const {data: res} = await sysGoods({
                    cate_id: this.cateId, //分类id
                    price_start: this.priceStart, //开始价格
                    price_end: this.priceEnd, //结束价格
                    order_by: this.orderbyId, //排序值
                    goods_brand_id: this.brandValue, //品牌id
                    page: this.page //页码
                })
                if(res.status == 200) {
                    console.log('goodslist',res)
                    this.goodsList = res.success.list
                    this.count = parseInt(res.success.count)
                    this.page = parseInt(res.success.page)
                }
            },
            // 左侧分类点击事件
            clickChange(id){
                this.cateId = id
                this.getsysGoods()
            },
            // 获取分类
            async getCate(){
                const {data: res} = await pCate()
                if(res.status == 200) {
                    this.cateList = res.success
                }
                console.log('分类',res)
            },
            // 品牌下拉点击事件
            brandChange(e){
                this.brandValue = e
                this.getsysGoods()
            },
            // 商品分类排序点击事件
            orderbyChange(e){
                this.orderbyValue = e
                for(var i = 0; i < this.orderbyList.length; i++) {
                    if(this.orderbyValue == this.orderbyList[i].value) {
                        this.orderbyId = this.orderbyList[i].value
                    }
                }
                this.getsysGoods()
            },
              // 去详情页面
            toDetails(id) {
                this.$router.push({path: '/index/shopdetailbuyer', query: {id: id}})
            },
            getPageNo(pageNo){
                console.log(pageNo)

                this.page = pageNo
            },
            // 上一页
			prevChange(e){
				this.page = e
				this.getsysGoods()
			},
			// 下一页
			nextChange(e){
				this.page = e
				this.getsysGoods()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
				this.getsysGoods()
			},
            // 滑块事件
            sliderChange(e){
                this.priceStart = e[0]
                this.priceEnd = e[1]
                this.getsysGoods()
            }
        },
    }
</script>

<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation : portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }
.pc {
    .bigbox {
        width: 1380px;
        margin: auto;
        margin-top: 30px;
        display: flex;
        .leftbox {
            width: 325px;
            .catebox {
                width: 100%;
                .cate {
                    width: 325px;
                    height: 56px;
                    background: #00adf1;
                    border-radius: 4px 4px 0px 0px;
                    box-sizing: border-box;
                    padding-left: 20px;
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: left;
                    line-height: 56px;
                }
                .womenbox {
                    width: 325px;
                    background: #ffffff;
                    border-radius: 0px 0px 4px 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    .items {
                        width: 325px;
                        line-height: 30px;
                        box-sizing: border-box;
                        padding: 5px 20px;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                    }
                    .items:hover {
                        background: #00adf1;
                        color: #fff;
                    }
                }
            }
            .huakuai {
                width: 100%;
                height: 136px;
                background: #ffffff;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 0 20px;
                margin-top: 20px;
                & > span {
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #000000;
                    display: inline-block;
                    width: 100%;
                    text-align: left;
                    margin-top: 20px;
                }
                .huak {
                    width: 100%;
                    margin-top: 15px;
                }
                .zhibox {
                    width: 100%;
                    font-size: 18px;
                    font-family: Arial;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 5px;
                }
            }
        }
        .rightbox {
            width: 1080px;
            margin-left: 30px;
            .titlebox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .titles {
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 600;
                    color: #000000;
                    text-align: left;
                }
                .selectbox {
                    display: flex;
                    .selectitem {
                        width: 190px;
                        display: flex;
                        text-align: left;
                        flex-direction: column;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                    }
                    .selectitem:nth-child(2) {
                        margin-left: 10px;
                    }
                }
            }
            .shopbox {
                width: 100%;
                .xunhuan {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .itemshop {
                        width: 240px;
                        height: 330px;
                        background: #fff;
                        box-sizing: border-box;
                        padding: 15px;
                        margin-top: 20px;
                        border-radius: 6px;
                        margin-right: 21px;
                        img {
                            width: 210px;
                            height: 210px;
                            cursor: pointer;
                        }
                        .wenzihe {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            & > span {
                                font-size: 18px;
                                font-family: Arial;
                                font-weight: bold;
                                color: #53bef4;
                                line-height: 22px;
                            }
                            .miaosu {
                                font-size: 14px;
                                font-family: Arial;
                                font-weight: 400;
                                color: #111111;
                                line-height: 20px;
                                width: 100%;
                                height: 40px;
                                -webkit-line-clamp: 2;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                cursor: pointer;
                            }
                        }
                    }
                    .itemshop:nth-of-type(4n + 0) {
                        margin-right: 0 !important;
                    }
                }
                .fenye {
                    width: 100%;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
        }
    }
}
.phone {
    .bigbox {
        width: 96%;
        margin: auto;
        margin-top: 30px;
        .huakuai {
            width: 100%;
            height: 200px;
            background: #ffffff;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 0 20px;
            margin-top: 20px;
            & > span {
                font-size: 28px;
                font-family: Arial;
                font-weight: 400;
                color: #000000;
                display: inline-block;
                width: 100%;
                text-align: left;
                margin-top: 20px;
            }
            .huak {
                width: 100%;
                margin-top: 30px;
            }
            .zhibox {
                width: 100%;
                font-size: 28px;
                font-family: Arial;
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
            }
        }
        .rightbox {
            width: 100%;
            .titlebox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .titles {
                    font-size: 32px;
                    font-family: Arial;
                    font-weight: 600;
                    color: #000000;
                    text-align: left;
                }
                .selectbox {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    .selectitem {
                        width: 48%;
                        display: flex;
                        text-align: left;
                        flex-direction: column;
                        font-size: 40px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                        .m-2 {
                            height: 70px;
                            margin-top: 10px;
                            font-size: 32px !important;
                            :deep(.el-input) {
                                height: 70px !important;
                                font-size: 32px !important;
                            }
                            :deep(.el-select .el-input,.el-select-dropdown .el-select-dropdown__item) {
                                font-size: 32px !important;
                            }
                        }
                    }
                }
            }
            .shopbox {
                width: 100%;
                .xunhuan {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    .itemshop {
                        width: 48%;
                        height: 560px;
                        background: #fff;
                        box-sizing: border-box;
                        padding: 15px;
                        margin-top: 20px;
                        border-radius: 6px;
                        margin-right: 4%;
                        .itemimg {
                            width: 100%;
                            height: 380px;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .wenzihe {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            margin-top: 10px;
                            & > span {
                                font-size: 36px;
                                font-family: Arial;
                                font-weight: bold;
                                color: #53bef4;
                                line-height: 30px;
                            }
                            .el-rate {
                                margin: 10px 0px;
                                :deep(.el-rate__icon) {
                                    font-size: 40px !important;
                                }
                            }
                            .miaosu {
                                font-size: 30px;
                                font-family: Arial;
                                font-weight: 400;
                                color: #111111;
                                line-height: 30px;
                                width: 100%;
                                height: 60px;
                                -webkit-line-clamp: 2;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                cursor: pointer;
                            }
                        }
                    }
                    .itemshop:nth-of-type(2n + 0) {
                        margin-right: 0 !important;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
        }
    }
}
</style>