<template>
	<!-- 买家注册 -->
	<div>
		<div class="pc">
			<div class="centbox">
				<div class="titles">
					<text>{{ $t('buyer.register.createAccount') }}</text>
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('buyer.register.fullName')" v-model="uname">
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('buyer.register.email')" v-model="uemail">
				</div>
				<div class="inpbox">
					<input type="password" :placeholder="$t('buyer.register.password')" v-model="member_pwd">
				</div>
				<div class="inpbox">
					<input type="password" :placeholder="$t('buyer.register.confirmPassword')" v-model="member_pwd_two">
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('buyer.register.inviteCode')" v-model="invitation_code">
				</div>
				<div class="create" @click="registerChange">
					<text>{{ $t('buyer.register.register') }}</text>
				</div>
				<div class="havex">
					<text>{{ $t('buyer.register.alreadyAccount') }}</text>
				</div>
				<div class="logoin" @click="tologin">
					<text>{{ $t('buyer.register.loginIn') }}</text>
				</div>
			</div>
		</div>
		<div class="phone">
			<div class="centbox">
				<div class="titles">
					<text>{{ $t('buyer.register.createAccount') }}</text>
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('buyer.register.fullName')" v-model="uname">
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('buyer.register.email')" v-model="uemail">
				</div>
				<div class="inpbox">
					<input type="password" :placeholder="$t('buyer.register.password')" v-model="member_pwd">
				</div>
				<div class="inpbox">
					<input type="password" :placeholder="$t('buyer.register.confirmPassword')" v-model="member_pwd_two">
				</div>
				<div class="inpbox">
					<input type="text" :placeholder="$t('buyer.register.inviteCode')" v-model="invitation_code">
				</div>
				<div class="create" @click="registerChange">
					<text>{{ $t('buyer.register.register') }}</text>
				</div>
				<div class="havex">
					<text>{{ $t('buyer.register.alreadyAccount') }}</text>
				</div>
				<div class="logoin" @click="tologin">
					<text>{{ $t('buyer.register.loginIn') }}</text>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {buyerReg} from '../../api/api.js'
import { ElMessage } from 'element-plus'
	export default {
		data() {
			return {
				uname: '', //用户名
				uemail: '', //邮箱
				member_pwd: '', //密码
				member_pwd_two: '', //确认密码
				invitation_code: '', //邀请码
			}
		},
		mounted(){
			let _this = this
			let url = window.location.href
			if(_this.getUrlParams3(url).codes) {
				_this.invitation_code = _this.getUrlParams3(url).codes
			}
		},
		methods: {
			// 获取url参数
			getUrlParams3(url){
				// \w+ 表示匹配至少一个(数字、字母及下划线), [\u4e00-\u9fa5]+ 表示匹配至少一个中文字符
				let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
				let result = {};
				url.replace(pattern, ($, $1, $2)=>{
					result[$1] = $2;
				})
				return result
        	},
			// 去登录
			tologin(){
				this.$router.push('/index/login')
			},
			// 注册
			async registerChange(){
				if(this.uname == '') {
					return ElMessage.error('enter one user name')
				}
				if(this.uemail == '') {
					return ElMessage.error('Please enter your email address')
				}
				if(this.member_pwd == '') {
					return ElMessage.error('Please enter the password')
				}
				if(this.member_pwd_two == '') {
					return ElMessage.error('Please enter a confirmation password')
				}
				if(this.member_pwd !== this.member_pwd_two) {
					return ElMessage.error('The two passwords do not match')
				}
				if(this.invitation_code == '') {
					return ElMessage.error('Please enter the invitation code')
				}
				const {data: res} = await buyerReg({
					member_name: this.uname,
					email: this.uemail,
					member_pwd: this.member_pwd,
					member_pwd_two: this.member_pwd_two,
					invitation_code: this.invitation_code
				})
				if(res.status == 200) {
					ElMessage.success(res.message)
					sessionStorage.setItem('member_role', res.success.member_role)
					sessionStorage.setItem('member_token', res.success.member_token)
					setTimeout(()=>{
						this.$router.push('/index/mypage/dashboard')
					},2000)
				}else {
					ElMessage.error(res.message)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	// 横屏
	@media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation : portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}

	.pc {
		.centbox {
			width: 450px;
			background-color: #fff;
			margin: auto;
			margin-top: 50px;
			box-sizing: border-box;
			padding: 30px 25px;
			.titles {
				font-size: 24px;
				font-family: Arial;
				font-weight: bold;
				width: 100%;
				text-align: center;
			}
			.inpbox {
				width: 100%;
				height: 45px;
				border: solid 1px #e6e6e6;
				border-radius: 5px;
				margin-top: 15px;
				display: flex;
				align-items: center;
				input {
					font-size: 14px;
					height: 45px;
					box-sizing: border-box;
					padding-left: 10px;
					width: 100%;
					border: none;
					outline: none;
				}
			}
			.xzbox {
				margin-top: 15px;
				cursor: pointer;
			}
			.create {
				cursor: pointer;
				width: 400px;
				height: 47px;
				background: #00ADF1;
				border-radius: 5px;
				font-size: 18px;
				font-family: Arial;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 47px;
				text-align: center;
				margin-top: 18px;
			}
			.havex {
				width: 100%;
				height: 14px;
				font-size: 14px;
				font-family: Arial;
				font-weight: 400;
				color: #969696;
				text-align: center;
				margin-top: 35px;
			}
			.logoin {
				cursor: pointer;
				width: 100%;
				height: 14px;
				font-size: 14px;
				font-family: Arial;
				font-weight: 400;
				color: #00ADF1;
				text-align: center;
				margin-top: 10px;
			}
		}
	}
	.phone {
		.centbox {
			width: 90%;
			background-color: #fff;
			margin: auto;
			margin-top: 50px;
			box-sizing: border-box;
			padding: 30px 25px;
			.titles {
				font-size: 40px;
				font-family: Arial;
				font-weight: bold;
				width: 100%;
				height: 100px;
				line-height: 100px;
				text-align: center;
			}
			.inpbox {
				width: 100%;
				height: 100px;
				border: solid 3px #e6e6e6;
				border-radius: 5px;
				margin-top: 20px;
				display: flex;
				align-items: center;
				input {
					width: 100%;
					height: 100px;
					font-size: 24px;
					height: 100px;
					box-sizing: border-box;
					padding-left: 10px;
					width: 100%;
					border: none;
					outline: none;
				}
			}
			.xzbox {
				margin-top: 15px;
				cursor: pointer;
			}
			.create {
				cursor: pointer;
				width: 100%;
				height: 80px;
				background: #00ADF1;
				border-radius: 5px;
				font-size: 30px;
				font-family: Arial;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 80px;
				text-align: center;
				margin-top: 20px;
			}
			.havex {
				width: 100%;
				height: 30px;
				font-size: 24px;
				font-family: Arial;
				font-weight: 400;
				color: #969696;
				text-align: center;
				margin-top: 35px;
			}
			.logoin {
				cursor: pointer;
				width: 100%;
				height: 30px;
				font-size: 24px;
				font-family: Arial;
				font-weight: 400;
				color: #00ADF1;
				text-align: center;
				margin-top: 10px;
			}
		}
	}
</style>
