<template>
    <div class="pc">
        这是秒杀详情
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {

        }
    }
</script>

<style lang="less">

</style>