<template>
 <!-- 对话 -->
    <div class="pc">
        <div class='bodys'>
            <div class="title">
                <span>{{ $t('seller.home.conversations') }}</span>
            </div>
            <div class="bigbox">
                <div class="itemdh" v-for="item in dataList" :key="item.id" @click="toDuihuaDetail(item.id)">
                    <div class="leftuser">
                        <img :src="item.member_head">
                        <div class="uname">
                            <span>{{ item.title }}</span>
                            <span>{{ item.email }}</span>
                        </div>
                    </div>
                    <div class="righttext">
                        <div class="span1">
                            {{ item.content }}
                            <span v-if="item.new_status == 1">new</span>
                        </div>
                        <span>{{ item.created_at }}</span>
                    </div>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
    </div>
    <div class="phone">
        <div class='bodys'>
            <div class="title">
                <span>{{ $t('seller.home.conversations') }}</span>
            </div>
            <div class="bigbox">
                <div class="itemdh" v-for="item in dataList" :key="item.id" @click="toDuihuaDetail(item.id)">
                    <div class="leftuser">
                        <img :src="item.member_head">
                        <div class="uname">
                            <span>{{ item.title }}</span>
                            <span>{{ item.email }}</span>
                        </div>
                    </div>
                    <div class="righttext">
                        <div class="span1">
                            {{ item.content }}
                            <span v-if="item.new_status == 1">new</span>
                        </div>
                        <span>{{ item.created_at }}</span>
                    </div>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :total="count"
                    class="my-pagination"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import {converSeller} from '@/api/sellerapi'
    import { ElMessage} from 'element-plus'
    export default {
        data () {
            return {
                page: 1,
                dataList: [],
                count: 0
            }
        },
        mounted(){
            this.getHuihua()
        },
        methods:{
            // 去会话详情
            toDuihuaDetail(id){
                this.$router.push({path: '/Home/SellerConversationsWith', query: {id: id}})
            },
            // 获取会话
            async getHuihua(){
                const {data: res} = await converSeller({
                    page: this.page
                })
                if(res.status == 200) {
                    this.count = parseInt(res.success.count)
                    this.dataList = res.success.list
                    console.log('会话',res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 上一页
            prevChange(e){
				this.page = e
                this.getHuihua()
			},
			// 下一页
			nextChange(e){
				this.page = e
                this.getHuihua()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
                this.getHuihua()
			}
        }
    }
</script>
<style lang="less" scoped>

    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}

    .pc {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .title {
                width: 100%;
                height: 60px;
                line-height: 60px;
                font-size: 20px;
                font-family: Arial;
                text-align: left;
                font-weight: 600;
            }
            .bigbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 10px 30px;
                .itemdh {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: solid 1px #DFDFDF;
                    min-height: 100px;
                    .leftuser {
                        display: flex;
                        align-items: center;
                        img {
                            width: 66px;
                            height: 66px;
                            border-radius: 50%;
                            overflow: hidden;
                            margin-left: 10px;
                        }
                        .uname {
                            display: flex;
                            flex-direction: column;
                            margin-left: 20px;
                            text-align: left;
                            span:nth-child(1) {
                                font-size: 22px;
                            }
                            span:nth-child(2){
                                font-size: 16px;
                                color: #969696;
                                margin-top: 10px;
                            }
                        }
                    }
                    .righttext {
                        text-align: left;
                        .span1 {
                            width: 1050px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            text-overflow: ellipsis;
                            margin-bottom: 10px;
                            font-size: 16px;
                            cursor: pointer;
                            &>span {
                                padding: 2px 5px 3px 5px;
                                background: red;
                                color: #FFFFFF;
                                display: inline-block;
                                border-radius: 5px;
                            }
                        }
                        &>span {
                            color: #969696;
                            font-size: 14px;
                        }
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            .title {
                width: 100%;
                height: 60px;
                line-height: 60px;
                font-size: 30px;
                font-family: Arial;
                text-align: left;
                font-weight: 600;
            }
            .bigbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                box-sizing: border-box;
                padding: 30px;
                .itemdh {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    border-bottom: solid 2px #DFDFDF;
                    min-height: 100px;
                    .leftuser {
                        display: flex;
                        align-items: center;
                        img {
                            width:80px;
                            height: 80px;
                            border-radius: 50%;
                            overflow: hidden;
                            margin-left: 10px;
                        }
                        .uname {
                            display: flex;
                            flex-direction: column;
                            margin-left: 20px;
                            text-align: left;
                            span:nth-child(1) {
                                font-size: 32px;
                            }
                            span:nth-child(2){
                                font-size: 26px;
                                color: #969696;
                                margin-top: 10px;
                            }
                        }
                    }
                    .righttext {
                        text-align: left;
                        margin-top: 20px;
                        .span1 {
                            width: 100%;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            -webkit-line-clamp: 2;
                            text-overflow: ellipsis;
                            margin-bottom: 10px;
                            font-size: 26px;
                            cursor: pointer;
                            &>span {
                                padding: 2px 5px 3px 5px;
                                background: red;
                                color: #FFFFFF;
                                display: inline-block;
                                border-radius: 5px;
                            }
                        }
                        &>span {
                            color: #969696;
                            font-size: 24px;
                        }
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
        }
    }
</style>