<template>
    <div class="pc">
        <h1>{{ $t('button.button.AllCoupons') }}</h1>
    </div>
    <div class="phone">
        <h1> {{ $t('button.button.AllCoupons') }}</h1>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation: portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }
    .pc {
        width: 100%;
        height: 200px;
        background: #111723;
        color: #fff;
        line-height: 160px;
    }
    .phone {
        width: 100%;
        height: 200px;
        background: #111723;
        color: #fff;
        line-height: 160px;
    }
</style>