<template>
  <nav>
    <router-view></router-view>
  </nav>
  <router-div/>
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 0;
  margin: 0;
  border: 0;
  background: #f6f6f6;
}
</style>
