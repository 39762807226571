<template>
    <!-- 全部分类 -->
    <div class="pc">
        <div class="allclass">
            <div class="alltitle">
                <span>{{ $t('seller.product.allCategory') }}</span>
            </div>
            <div class="classbigbox">
                <div class="itemclass" v-for="item in cateList" :key="item.id" @click="toDetail">
                    <span>{{ item.name }}</span>
                    <img src="../../assets/img/youjiantou-hei.png" />
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="allclass">
            <div class="alltitle">
                <span>{{ $t('seller.product.allCategory') }}</span>
            </div>
            <div class="classbigbox">
                <div class="itemclass" v-for="item in cateList" :key="item.id" @click="toDetail">
                    <span>{{ item.name }}</span>
                    <img src="../../assets/img/youjiantou-hei.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { pCate } from '@/api/buyerapi'
export default {
    data() {
        return {
            cateList: [],
        }
    },
    mounted() {
        this.getCate()
    },
    methods: {
        // 去分类详情
        toDetail() {
            this.$router.push('/index/categorieydetail')
        },
        // 获取分类
        async getCate() {
            const { data: res } = await pCate()
            if (res.status == 200) {
                this.cateList = res.success
            }
        },
    },
}
</script>


<style lang="less" scoped>

// 横屏
@media all and (orientation: landscape) {
    .pc {
        display: block !important;
    }
    .phone {
        display: none !important;
    }
}

/* 竖屏*/
@media all and (orientation : portrait) {
    .pc {
        display: none !important;
    }
    .phone {
        display: block !important;
    }
}

.pc {
    .allclass {
        width: 1380px;
        min-height: 100px;
        margin: auto;
        margin-top: 30px;
        .alltitle {
            font-size: 24px;
            font-family: Arial;
            font-weight: 400;
            text-align: left;
        }
        .classbigbox {
            width: 100%;
            .itemclass {
                background: #fff;
                margin-top: 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 90px;
                box-sizing: border-box;
                padding: 0 30px;
                cursor: pointer;
                span {
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 400;
                }
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.phone {
    .allclass {
        width: 100%;
        min-height: 120px;
        margin: auto;
        margin-top: 30px;
        box-sizing: border-box;
        padding: 0 20px;
        .alltitle {
            font-size: 32px;
            font-family: Arial;
            font-weight: 400;
            text-align: left;
        }
        .classbigbox {
            width: 100%;
            .itemclass {
                background: #fff;
                margin-top: 25px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 90px;
                box-sizing: border-box;
                padding: 0 30px;
                cursor: pointer;
                span {
                    font-size: 28px;
                    font-family: Arial;
                    font-weight: 400;
                }
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}
</style>