<template>
    <!-- 佣金历史记录 -->
       <div class="pc">
            <div class='bodys'>
            <div class="allbox">
                <div class="title">
                    <span>{{ $t('seller.commissionHistory.commissionHistory') }}</span>
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column type="index" label="#" width="300" />
                        <el-table-column prop="order_on" :label="$t('seller.orderDetail.orderCode')" width="300" />
                        <el-table-column prop="admin_commission" :label="$t('seller.commissionHistory.adminCommission')" width="300" />
                        <el-table-column prop="earning" :label="$t('seller.order.earning')" width="310" />
                        <el-table-column prop="created_at" :label="$t('seller.commissionHistory.createdAt')" width="300" />
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    layout="prev, pager, next"
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
       </div>
       <div class="phone">
            <div class='bodys'>
            <div class="allbox">
                <div class="title">
                    <span>{{ $t('seller.commissionHistory.commissionHistory') }}</span>
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column prop="order_on" :label="$t('seller.orderDetail.orderCode')">
                            <template #default="scope">
                                <span>{{ scope.row.order_on }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="admin_commission" :label="$t('seller.commissionHistory.adminCommission')">
                            <template #default="scope">
                                <span>{{ scope.row.admin_commission }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="earning" :label="$t('seller.order.earning')">
                            <template #default="scope">
                                <span>{{ scope.row.earning }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" :label="$t('seller.commissionHistory.createdAt')">
                            <template #default="scope">
                                <span>{{ scope.row.created_at }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    layout="prev, pager, next"
                    :total="count"
                    class="my-pagination"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
        </div>
       </div>
   </template>
   <script>
        import {commissionHistory} from "@/api/sellerapi"
        import { ElMessage } from 'element-plus'
        export default {
            data () {
                return {
                    page: 1,
                    count: 0,
                    tableData: []
                }
            },
            mounted(){
                this.getcommissionHistory()
            },
            methods:{
                 // 获取佣金历史记录
                async getcommissionHistory(){
                    const {data: res} = await commissionHistory({
                        page: this.page
                    })
                    if(res.status == 200) {
                        this.tableData = res.success.list
                        this.page = res.success.page
                        this.count = parseInt(res.success.count)
                    }else {
                        ElMessage.error(res.message)
                    }
                },
                // 上一页
                prevChange(e){
                    this.page = e
                    this.getcommissionHistory
                },
                // 下一页
                nextChange(e){
                    this.page = e
                    this.getcommissionHistory
                },
                // 点击分页的数字
                currentChaneg(e){
                    this.page = e
                    this.getcommissionHistory
                }
            }
        }
   </script>
   <style lang="less" scoped>

    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}
    .pc {
        .bodys {
           width: 100%;
           box-sizing: border-box;
           padding: 0 25px;
           .allbox {
               width: 100%;
               background: #FFFFFF;
               border: 1px solid #DFDFDF;
               border-radius: 4px;
               margin-top: 30px;
                .title {
                   width: 100%;
                   height: 55px;
                   text-align: left;
                   line-height: 55px;
                   font-size: 18px;
                   box-sizing: border-box;
                   padding: 0 30px;
                   border-bottom: 1px solid #DFDFDF;
               }
                .tabbox {
                   width: 100%;
                   box-sizing: border-box;
                   padding: 0 30px;
                   text-align: left;
                   margin-bottom: 30px;
                }
           }
            .fenye {
                width: 100%;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .allbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                margin-top: 30px;
                    .title {
                    width: 100%;
                    height: 60px;
                    text-align: left;
                    line-height: 60px;
                    font-size: 28px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 2px solid #DFDFDF;
                }
                    .tabbox {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    text-align: left;
                    margin-bottom: 30px;
                    span {
                        font-size: 24px;
                    }
                    }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
        }
    }
   </style>