<template>
 <!-- 收到的退款请求 -->
    <div class="pc">
        <div class='bodys'>
            <div class="titles">
                <span>{{ $t('seller.appliedRefundRequest.appliedRefundRequest') }}</span>
            </div>
            <div class="alltab">
                <div class="refundbox">
                    <span>{{ $t('seller.appliedRefundRequest.allRefundRequest') }}</span>
                </div>
                <div class="tables">
                    <el-table :data="refundsList" style="width: 100%">
                        <el-table-column type="index" label="#" width="80" />
                        <el-table-column prop="refund_time" :label="$t('agent.sellerOrder.orderDate')" width="180" />
                        <el-table-column prop="order_no" :label="$t('seller.order.orderId')" width="180" />
                        <el-table-column prop="goods_name" :label="$t('seller.appliedRefundRequest.product')" width="590" />
                        <el-table-column prop="total_price" :label="$t('seller.appliedRefundRequest.amount')" width="100" />
                        <el-table-column prop="refund_seller_status_str" :label="$t('seller.appliedRefundRequest.status')" width="100">
                            <template #default="scope">
                                <span class="span1">{{ scope.row.refund_seller_status_str }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="refund_reason" :label="$t('seller.appliedRefundRequest.reason')" width="100">
                            <template #default="scope">
                                <span class="span1">{{ scope.row.refund_reason }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="refund_seller_status" :label="$t('seller.paymentHistory.approval')" width="100">
                            <template #default="scope">
                                <div v-if="scope.row.refund_seller_status != 2">
                                    <el-switch 
                                        @change="switchChange(scope.row.id)"
                                        v-model="scope.row.refund_seller_status"
                                        active-value="3"
                                        inactive-value="1"
                                    />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="refuse_status" :label="$t('seller.appliedRefundRequest.reject')">
                            <template #default="scope">
                                <div class="img2">
                                    <img src="../../../assets/img/history-yanjing.png" v-if="scope.row.refuse_status == 2" @click="lookDetail(scope.row.refund_reason)">
                                    <img src="../../../assets/img/history-shanchu.png" v-if="scope.row.refuse_status == 1" @click="showTc(scope.row.id)">
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- 拒绝退款弹窗 -->
            <div class="tanchuang" v-if="tkshow">
                <div class="contentbox">
                    <div class="titlebox">
                        <span>{{ $t('seller.loanQuotaRequestHistories.refuseRefundRequest') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="guanbiTc">
                    </div>
                    <div class="centbox">
                        <span>{{ $t('seller.loanQuotaRequestHistories.rejectReason') }}</span>
                        <textarea v-model="content"></textarea>
                    </div>
                    <div class="btombox">
                        <span @click="guanbiTc">{{ $t('seller.conversations.cancel') }}</span>
                        <span @click="submitTk">{{ $t('seller.makeCryptoPayment.confirmation') }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="fenye">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="1000"
                />
            </div> -->
        </div>
    </div>
    <div class="phone">
        <div class='bodys'>
            <div class="titles">
                <span>{{ $t('seller.appliedRefundRequest.appliedRefundRequest') }}</span>
            </div>
            <div class="alltab">
                <div class="refundbox">
                    <span>{{ $t('seller.appliedRefundRequest.allRefundRequest') }}</span>
                </div>
                <div class="tables">
                    <el-table :data="refundsList" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column type="index" label="#"/>
                        <el-table-column prop="refund_time" :label="$t('agent.sellerOrder.orderDate')" width="180" />
                        <el-table-column prop="order_no" :label="$t('seller.order.orderId')" width="180" />
                        <el-table-column prop="goods_name" :label="$t('seller.appliedRefundRequest.product')" width="500" />
                        <el-table-column prop="total_price" :label="$t('seller.appliedRefundRequest.amount')" width="100" />
                        <el-table-column prop="refund_seller_status_str" :label="$t('seller.appliedRefundRequest.status')" width="100">
                            <template #default="scope">
                                <span class="span1">{{ scope.row.refund_seller_status_str }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="refund_reason" :label="$t('seller.appliedRefundRequest.reason')" width="100">
                            <template #default="scope">
                                <span class="span1">{{ scope.row.refund_reason }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="refund_seller_status" :label="$t('seller.paymentHistory.approval')" width="100">
                            <template #default="scope">
                                <div v-if="scope.row.refund_seller_status != 2">
                                    <el-switch 
                                        @change="switchChange(scope.row.id)"
                                        v-model="scope.row.refund_seller_status"
                                        active-value="3"
                                        inactive-value="1"
                                    />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="refuse_status" :label="$t('seller.appliedRefundRequest.reject')">
                            <template #default="scope">
                                <div class="img2">
                                    <img src="../../../assets/img/history-yanjing.png" v-if="scope.row.refuse_status == 2" @click="lookDetail(scope.row.refund_reason)">
                                    <img src="../../../assets/img/history-shanchu.png" v-if="scope.row.refuse_status == 1" @click="showTc(scope.row.id)">
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- 拒绝退款弹窗 -->
            <div class="tanchuang" v-if="tkshow">
                <div class="contentbox">
                    <div class="titlebox">
                        <span>{{ $t('seller.loanQuotaRequestHistories.refuseRefundRequest') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="guanbiTc">
                    </div>
                    <div class="centbox">
                        <span>{{ $t('seller.loanQuotaRequestHistories.rejectReason') }}</span>
                        <textarea v-model="content"></textarea>
                    </div>
                    <div class="btombox">
                        <span @click="guanbiTc">{{ $t('seller.conversations.cancel') }}</span>
                        <span @click="submitTk">{{ $t('seller.makeCryptoPayment.confirmation') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {refundList,orderRefuse,orderAgree} from "@/api/sellerapi"
    import { ElMessage,ElMessageBox} from 'element-plus'
    export default {
        data () {
            return {
                id: '',
                tkshow: false, //退款弹窗
                refundsList: [],// 退款列表
                content: '' //输入的退款原因
            }
        },
        mounted(){
            this.getRefundList()
        },
        methods:{
            // 获取退款列表
            async getRefundList(){
                const {data: res} = await refundList()
                if(res.status == 200) {
                    this.refundsList = res.success
                    console.log(res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 同意退款
            async switchChange(id){
                const {data: res} = await orderAgree({
                    id: id
                })
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getRefundList()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 关闭弹窗
            guanbiTc(){
                this.tkshow = false
                this.content = ''
            },
            // 拒绝退款弹窗显示
            showTc(id){
                this.id = id
                this.tkshow = true
            },
            // 查看退款原因
            lookDetail(e){
                ElMessageBox.alert(e,'Refund request rejection reason',{
                    confirmButtonText: 'close',
                    callback: ()=>{}
                })
            },
            // 拒绝退款提交
            async submitTk(){
                if(this.content == '') {return ElMessage.error('Please enter the refund reason')}
                const {data: res} = await orderRefuse({
                    id: this.id, //订单id
                    refuse_reason: this.content //拒绝理由
                })
                this.tkshow = false
                this.content = ''
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getRefundList()
                }else {
                    ElMessage.error(res.message)
                }
            }
        }
    }
</script>
<style lang="less" scoped>

    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}

    .pc {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .titles {
                width: 100%;
                height: 60px;
                font-size: 18px;
                font-weight: 600;
                text-align: left;
                line-height: 60px;
            }
            .alltab {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                padding-bottom: 30px;
                .refundbox {
                    width: 100%;
                    height: 55px;
                    text-align: left;
                    line-height: 55px;
                    font-size: 18px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 1px solid #DFDFDF;
                }
                .tables {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    .span1 {
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #FFFFFF;
                        display: inline-block;
                        background: #0ABB75;
                        border-radius: 4px;
                        padding: 2px 5px;
                    }
                    .img2 {
                        img{
                            width: 30px;
                            height: 30px;
                            margin: 0 5px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .tanchuang {
                position: fixed;
                z-index: 999999;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                top: 0;
                left: 0;
                .contentbox {
                    width: 30%;
                    background: #fff;
                    margin: auto;
                    margin-top: 20vh;
                    border-radius: 5px;
                    .titlebox {
                        width: 100%;
                        height: 80px;
                        border-bottom: solid 1px #e6e6e6;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 20px;
                        font-size: 18px;
                        img {
                            width: 25px;
                            height: 25px;
                            cursor: pointer;
                        }
                    }
                    .centbox {
                        display: flex;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 20px;
                        width: 100%;
                        height: 180px;
                        margin-top: 40px;
                        font-size: 18px;
                        textarea {
                            width: 70%;
                            height: 140px;
                            border-radius: 5px;
                            border: solid 1px #e6e6e6;
                            outline: none;
                            resize:none;
                            box-sizing: border-box;
                            padding: 10px;
                        }
                    }
                    .btombox {
                        width: 100%;
                        height: 80px;
                        border-top: solid 1px #e6e6e6;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0 20px;
                        span {
                            display: inline-block;
                            width: 100px;
                            height: 50px;
                            border-radius: 5px;
                            font-size: 18px;
                            text-align: center;
                            line-height: 50px;
                            cursor: pointer;
                            margin-left: 20px;
                        }
                        span:nth-child(1){
                            background: #DFDFDF;
                            color: #000;
                        }
                        span:nth-child(2){
                            background: #0ABB75;
                            color: #fff;
                        }
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .titles {
                width: 100%;
                height: 80px;
                font-size: 28px;
                font-weight: 600;
                text-align: left;
                line-height: 80px;
            }
            .alltab {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                padding-bottom: 30px;
                .refundbox {
                    width: 100%;
                    height: 60px;
                    text-align: left;
                    line-height: 60px;
                    font-size: 28px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 1px solid #DFDFDF;
                }
                .tables {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    .span1 {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #FFFFFF;
                        display: inline-block;
                        background: #0ABB75;
                        border-radius: 5px;
                        padding: 5px 10px;
                    }
                    .img2 {
                        img{
                            width: 40px;
                            height: 40px;
                            margin: 0 5px;
                        }
                    }
                }
            }
            .tanchuang {
                position: fixed;
                z-index: 999;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                top: 0;
                left: 0;
                .contentbox {
                    width: 80%;
                    background: #fff;
                    margin: auto;
                    margin-top: 20vh;
                    border-radius: 5px;
                    .titlebox {
                        width: 100%;
                        height: 80px;
                        border-bottom: solid 1px #e6e6e6;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 20px;
                        font-size: 28px;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .centbox {
                        display: flex;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 20px;
                        width: 100%;
                        height: 180px;
                        margin-top: 40px;
                        font-size: 28px;
                        textarea {
                            width: 70%;
                            height: 140px;
                            border-radius: 5px;
                            border: solid 1px #e6e6e6;
                            outline: none;
                            resize:none;
                            box-sizing: border-box;
                            padding: 10px;
                            font-size: 24px;
                        }
                    }
                    .btombox {
                        width: 100%;
                        height: 80px;
                        border-top: solid 1px #e6e6e6;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 0 20px;
                        span {
                            display: inline-block;
                            width: 160px;
                            height: 60px;
                            border-radius: 5px;
                            font-size: 28px;
                            text-align: center;
                            line-height: 60px;
                            cursor: pointer;
                            margin-left: 20px;
                        }
                        span:nth-child(1){
                            background: #DFDFDF;
                            color: #000;
                        }
                        span:nth-child(2){
                            background: #0ABB75;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>