<template>
    <div class="bigbox">
        <div class="titlebox">
            <span>Upload New File</span>
            <span @click="fanhui">Back to uploaded files</span>
        </div>
        <div class="uploadbox">
            <div class="dragbox">
                <span>Drag & drop your files</span>
            </div>
            <div class="uploads">
                <el-upload
                    v-model:file-list="fileList"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                >   
                    <span style="font-size: 60px;color: #969696;">+</span>
            </el-upload>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data(){
            return {
                fileList: [
                    {
                        name: 'food.jpeg',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
                    },
                    {
                        name: 'plant-1.png',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
                    },
                    {
                        name: 'food.jpeg',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
                    },
                    {
                        name: 'plant-2.png',
                        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
                    },
                    
                ] 
            }
        },
        methods: {
            // 返回上一页
            fanhui(){
                this.$router.push('/Home/SellerUploadFiles')
            }
        }
    }
</script>

<style lang="less" scoped>
    .bigbox {
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px;
        .titlebox {
            width: 100%;
            height: 70px;
            line-height: 70px;
            display: flex;
            justify-content: space-between;
            span:nth-child(1){
                font-size: 18px;
                font-family: Arial;
                font-weight: 400;
                color: #1A1A1A;
            }
            span:nth-child(2){
                font-size: 14px;
                font-family: Arial;
                font-weight: 400;
                color: #969696;
                cursor: pointer;
            }
        }
        .uploadbox {
            width: 100%;
            min-height: 730px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 4px;
            .dragbox {
                width: 100%;
                height: 50px;
                border-bottom: solid 1px #dcdcdc;
                line-height: 50px;
                text-align: left;
                box-sizing: border-box;
                padding-left: 30px;
            }
            .uploads {
                width: 1490px;
                min-height: 600px;
                background: #F6F6F6;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                margin-left: 40px;
                margin-top: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>