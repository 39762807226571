<template>
 <!-- 添加产品 -->
    <div class="pc">
        <div class='bodys'>
            <div class="titles">
                <span>{{ $t('seller.product.addYourProduct') }}</span>
            </div>
            <div class="basicinfo">
                <div class="basic">
                    <span>{{ $t('seller.product.productInformation') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.product.productName') }}</span>
                        <input type="text" :placeholder="$t('seller.product.productName')" v-model="proName">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.category') }}</span>
                        <el-select v-model="optionName1" class="m-2" :placeholder="$t('seller.addYourProduct.category')" size="large" @change="optionChange1">
                            <el-option
                            v-for="item in cateList"
                            :key="item.cate_id"
                            :label="item.name"
                            :value="item.name"
                            />
                        </el-select>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.brand') }}</span>
                        <el-select v-model="optionName2" class="m-2" placeholder="Select Brand" size="large" @change="optionChange2">
                            <el-option
                            v-for="item in brandList"
                            :key="item.goods_brand_id"
                            :label="item.name"
                            :value="item.name"
                            />
                        </el-select>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.unit') }}</span>
                        <input type="text" placeholder="Unit (e.g. KG, Pc etc)" v-model="unit">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.product.tags') }}</span>
                        <input type="text" placeholder="Type and hit enter to add a tag" v-model="tags">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.attribute') }}</span>
                        <input type="text" placeholder="xl,xxl,xxxl" v-model="specs">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.product.refundable') }}</span>
                        <div class="switch">
                            <el-switch v-model="switchs" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="basicinfo martop">
                <div class="basic">
                    <span>{{ $t('seller.addYourProduct.productImages') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.Gallery Images') }}</span>
                        <el-upload
                            v-model:file-list="fileList1"
                            class="upload-demo"
                            :action="uploadURL"
                            :on-success="uploadSuccess"
                            :on-error="handleError"
                            :limit="1"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="basicinfo martop">
                <div class="basic">
                    <span>{{ $t('seller.product.ProductPrice + stock') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.product.unitPrice') }}</span>
                        <input type="text" :placeholder="$t('seller.product.unitPrice')" v-model="unitPrice">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.product.discount') }}</span>
                        <input type="text" :placeholder="$t('seller.product.discount')" v-model="discount">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.orderDetail.QUANTITY') }}</span>
                        <input type="text" :placeholder="$t('seller.orderDetail.QUANTITY')" v-model="quantity">
                    </div>
                </div>
            </div>
            <div class="basicinfo martop">
                <div class="basic">
                    <span>{{ $t('seller.product.productDescription') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                        <textarea v-model="description"></textarea>
                    </div>
                </div>
            </div>
            <div class="savebox">
                <span @click="submitData">{{ $t('seller.shopSetting.save') }}</span>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class='bodys'>
            <div class="titles">
                <span>{{ $t('seller.product.addYourProduct') }}</span>
            </div>
            <div class="basicinfo">
                <div class="basic">
                    <span>{{ $t('seller.product.productInformation') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.product.productName') }}</span>
                        <input type="text" :placeholder="$t('seller.product.productName')" v-model="proName">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.category') }}</span>
                        <el-select v-model="optionName1" class="m-2" :placeholder="$t('seller.addYourProduct.category')" size="large" @change="optionChange1">
                            <el-option
                            style="font-size: 24px;height: 60px;"
                            v-for="item in cateList"
                            :key="item.cate_id"
                            :label="item.name"
                            :value="item.name"
                            />
                        </el-select>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.brand') }}</span>
                        <el-select v-model="optionName2" class="m-2" placeholder="Select Brand" size="large" @change="optionChange2">
                            <el-option
                            style="font-size: 24px;height: 60px;"
                            v-for="item in brandList"
                            :key="item.goods_brand_id"
                            :label="item.name"
                            :value="item.name"
                            />
                        </el-select>
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.unit') }}</span>
                        <input type="text" placeholder="Unit (e.g. KG, Pc etc)" v-model="unit">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.product.tags') }}</span>
                        <input type="text" placeholder="Type and hit enter to add a tag" v-model="tags">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.attribute') }}</span>
                        <input type="text" placeholder="xl,xxl,xxxl" v-model="specs">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.product.refundable') }}</span>
                        <div class="switch">
                            <el-switch v-model="switchs" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="basicinfo martop">
                <div class="basic">
                    <span>{{ $t('seller.addYourProduct.productImages') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.addYourProduct.Gallery Images') }}</span>
                        <el-upload
                            v-model:file-list="fileList1"
                            class="upload-demo"
                            :action="uploadURL"
                            :on-success="uploadSuccess"
                            :on-error="handleError"
                            :limit="1"
                            list-type="picture"
                        >
                            <span>Browse</span>
                            <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="basicinfo martop">
                <div class="basic">
                    <span>{{ $t('seller.product.ProductPrice + stock') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.product.unitPrice') }}</span>
                        <input type="text" :placeholder="$t('seller.product.unitPrice')" v-model="unitPrice">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.product.discount') }}</span>
                        <input type="text" :placeholder="$t('seller.product.discount')" v-model="discount">
                    </div>
                    <div class="itembox">
                        <span>{{ $t('seller.orderDetail.QUANTITY') }}</span>
                        <input type="text" :placeholder="$t('seller.orderDetail.QUANTITY')" v-model="quantity">
                    </div>
                </div>
            </div>
            <div class="basicinfo martop">
                <div class="basic">
                    <span>{{ $t('seller.product.productDescription') }}</span>
                </div>
                <div class="shopbox">
                    <div class="itembox">
                        <span>{{ $t('seller.makeCryptoPayment.description') }}</span>
                        <textarea v-model="description"></textarea>
                    </div>
                </div>
            </div>
            <div class="savebox">
                <span @click="submitData">{{ $t('seller.shopSetting.save') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import {cateAll,goodsEdit,Brand,goodsInfo} from '../../../api/sellerapi'
import {uploadURL} from '../../../api/api.js'
import { ElMessage } from 'element-plus'
export default {
    data () {
        return {
            cateList: [], //商品下拉选择分类
            brandList: [], //品牌下拉列表
            optionId1: '', //选择的商品分类id
            optionId2: '', //选择的品牌id
            optionName1: '',
            optionName2: '',
            proName: '',
            unit: '',
            tags: '',
            specs: '',
            switchs: true,
            fileList1: [], //上传图片1
            unitPrice: '',
            discount: '',
            quantity: '',
            description: '',
            uploadURL: uploadURL, //上传图片url
            imgurl: '', //上传成功的图片地址
            goodsId: '',//商品id
        }
    },
    mounted(){
        this.getcateAll()
        this.getBrandList()
        if(this.$route.query.id) {
            this.goodsId = this.$route.query.id
            this.getGoodsInfo()
        }else {
            this.goodsId = ''
        }
    },
    methods:{
        // 获取选择商品分类
        async getcateAll(){
            const {data: res} = await cateAll()
            if(res.status == 200) {
                this.cateList = res.success
            } else {
                console.log(res.message)
            }
            console.log(res)   
        },
        // 获取brand列表
        async getBrandList(){
            const {data: res} = await Brand()
            this.brandList = res.success
            console.log('品牌列表',res)
        },
        // 获取商品信息
        async getGoodsInfo(){
            const {data: res} = await goodsInfo({
                id: this.goodsId 
            })
            if(res.status == 200) {
                this.proName = res.success.goods_name
                this.unit = res.success.unit
                this.tags = res.success.tags
                this.specs = res.success.size
                this.unitPrice = res.success.unit_price
                this.discount = res.success.discount
                this.quantity = res.success.current_qty
                this.description = res.success.details
                if(res.success.tk_status == 1) {this.switchs = true}else {this.switchs = false}
                this.fileList1 = [{url: res.success.main_img_url}]
                this.optionId1 = res.success.cate_id
                this.optionId2 = res.success.brand_id
                for(let i = 0; i < this.cateList.length; i++) {
                    if(this.optionId1 === this.cateList[i].cate_id) {
                           this.optionName1 = this.cateList[i].name 
                    }
                }
                for(let k = 0; k < this.brandList.length; k++) {
                    if(this.optionId2 === this.brandList[k].goods_brand_id) {
                        this.optionName2 = this.brandList[k].name
                    }
                }
            }
            console.log('获取商品数据',res)
        },
        // 提交数据
        async submitData(){
            if(this.proName == ''){return ElMessage.error('Product name cannot be empty')}
            if(this.optionName1 == ''){return ElMessage.error('Please select a classification')}
            if(this.optionName2 == ''){return ElMessage.error('Please select a brand')}
            if(this.unit == ''){return ElMessage.error('Unit cannot be empty')}
            if(this.specs == ''){return ElMessage.error('Specification cannot be empty')}
            if(this.fileList1.length == 0){return ElMessage.error('Please upload pictures')}
            if(this.unitPrice == ''){return ElMessage.error('Unit price cannot be empty')}
            if(this.quantity == ''){return ElMessage.error('Quantity cannot be empty')}
            if(this.tags == ''){return ElMessage.error('Tags cannot be empty')}
            if(this.description == ''){return ElMessage.error('Description cannot be empty')}
            let values = ''
            if(this.switchs === true) {values = 1}else {values = 2}
            const {data: res} = await goodsEdit({
                id: this.goodsId, //商品id（修改商品时传入，添加时不用填）
                goods_name: this.proName, //商品标题(Product Name)
                cate_id: this.optionId1, //分类id(Category)
                brand_id: this.optionId2, //品牌id(Brand)
                main_img: this.imgurl, //商品图片
                unit_price: this.unitPrice, //商品价格（Unit price）
                size: this.specs, //商品规格(specs)
                current_qty: this.quantity, //库存(Quantity)
                discount: this.discount, //折扣(Discount)
                details: this.description, //商品详情(Description)
                unit: this.unit,
                tags: this.tags,
                tk_status: values
            })
            if(res.status == 200) {
                ElMessage.success(res.message)
                this.$router.push('/Home/SellerProducts')
            }else {
                ElMessage.error(res.message)
            }
        },
        // 下拉事件-分类
        optionChange1(e){
            this.optionName1 = e
            for(let i = 0; i < this.cateList.length; i++) {
                if(this.optionName1 === this.cateList[i].name) {
                    this.optionId1 = this.cateList[i].cate_id
                }
            }
        },
        // 下拉事件-品牌列表
        optionChange2(e){
            this.optionName2 = e
            for(let i = 0; i < this.brandList.length; i++) {
                if(this.optionName2 === this.brandList[i].name) {
                    this.optionId2 = this.brandList[i].goods_brand_id
                }
            }
        },
        // 图片上传失败
        handleError(){
            ElMessage.warning('Upload failed')
        },
        // 图片上传成功
        uploadSuccess(e){
            ElMessage.success('Uploaded successfully')
            this.imgurl = e.success.file_url
            console.log(e)
        }
    }
}
</script>
<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}
    .pc {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .titles {
                width: 100%;
                height: 65px;
                text-align: left;
                line-height: 65px;
                font-size: 18px;
                font-weight: 600;
            }
            .martop{
                margin-top: 30px;
            }
            .basicinfo {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                .basic {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    border-bottom: 1px solid #DFDFDF;
                    font-size: 18px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .shopbox {
                    box-sizing: border-box;
                    padding-left: 30px;
                    padding-bottom: 30px;
                    .itembox {
                        margin-top: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding-right: 30px;
                        input {
                            width: 1280px;
                            height: 50px;
                            border: 1px solid #DFDFDF;
                            border-radius: 4px;
                            outline: none;
                            box-sizing: border-box;
                            padding-left: 15px;
                            font-size: 14px;
                        }
                        :deep(.el-input__wrapper) {
                            width: 1250px;
                            height: 50px;
                        }
                        .switch {
                            width: 1280px;
                            height: 50px;
                            border-radius: 4px; 
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }
                        :deep(.el-upload) {
                            width: 1280px;
                            height: 50px;
                            border: 1px solid #DFDFDF;
                            border-radius: 4px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            span:nth-child(1){
                                width: 79px;
                                height: 50px;
                                line-height: 50px;
                                background: #DCDCDC;
                                border-radius: 4px;
                            }
                            span:nth-child(2){
                                margin-left: 20px;
                                color: #969696;
                            }
                        }
                        textarea {
                            width: 1280px;
                            height: 300px;
                            background: #FFFFFF;
                            border: 1px solid #DFDFDF;
                            border-radius: 4px;
                            outline: none;
                            box-sizing: border-box;
                            padding: 15px;
                            font-size: 14px;
                            resize: none;
                        }
                    }
                }
            }
            .savebox {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                margin-bottom: 50px;
                span {
                    width: 165px;
                    height: 50px;
                    background: #0277BD;
                    border-radius: 4px;
                    line-height: 50px;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
        }
    }
    .phone {
        .bodys {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .titles {
                width: 100%;
                height: 80px;
                text-align: left;
                line-height: 80px;
                font-size: 28px;
                font-weight: 600;
            }
            .martop{
                margin-top: 30px;
            }
            .basicinfo {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 4px;
                .basic {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 1px solid #DFDFDF;
                    font-size: 28px;
                    text-align: left;
                    box-sizing: border-box;
                    padding-left: 30px;
                }
                .shopbox {
                    box-sizing: border-box;
                    padding-left: 30px;
                    padding-bottom: 30px;
                    .itembox {
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        box-sizing: border-box;
                        padding-right: 30px;
                        font-size: 24px;
                        input {
                            width: 100%;
                            height: 80px;
                            border: 2px solid #DFDFDF;
                            border-radius: 4px;
                            outline: none;
                            box-sizing: border-box;
                            padding-left: 15px;
                            font-size: 24px;
                            margin-top: 10px;
                        }
                        .m-2 {
                            width: 100%;
                            margin-top: 10px;
                            :deep(.el-input) {
                                width: 100%;
                                height: 80px;
                                font-size: 24px;
                            }
                        }
                        :deep(.el-input__wrapper) {
                            width: 100%;
                            height: 80px;
                        }
                        .switch {
                            width: 100%;
                            height: 80px;
                            border-radius: 4px; 
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            :deep(.el-switch__core) {
                                width: 80px;
                                height: 40px;
                                border-radius: 20px;
                            }
                        }
                        :deep(.el-upload) {
                            width: 100%;
                            height: 80px;
                            border: 1px solid #DFDFDF;
                            border-radius: 4px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }
                        :deep(.upload-demo) {
                            width: 100%;
                            margin-top: 10px;
                            span:nth-child(1){
                                width: 100px;
                                height: 80px;
                                line-height: 80px;
                                background: #DCDCDC;
                                border-radius: 4px;
                            }
                            span:nth-child(2){
                                margin-left: 20px;
                                color: #969696;
                            }
                        }
                        textarea {
                            width: 100%;
                            height: 300px;
                            background: #FFFFFF;
                            border: 1px solid #DFDFDF;
                            border-radius: 4px;
                            outline: none;
                            box-sizing: border-box;
                            padding: 15px;
                            font-size: 24px !important;
                            resize: none;
                            margin-top: 10px;
                        }
                    }
                }
            }
            .savebox {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                margin-bottom: 50px;
                span {
                    width: 200px;
                    height: 60px;
                    background: #0277BD;
                    border-radius: 6px;
                    line-height: 60px;
                    text-align: center;
                    color: #fff;
                    font-size: 24px;
                }
            }
        }
    }
</style>