<template>
	<!-- 买家个人资料管理-手机端 -->
	<div class="phone">
		<div class="centbox">
			<div class="centleft">
				<div class="headimg">
					<img v-if="buyerInfo.member_head == ''" src="../../assets/img/touxiang.jpg">
					<img v-else :src="buyerInfo.member_head">
					<text class="untext">{{ buyerInfo.member_name }}</text>
					<text class="zhtext">{{ buyerInfo.email }}</text>
				</div>
				<div class="leftitembox">
					<div class="leftitem" @click="toDashboard" :class="index == 1? 'dtbj': ''">
						<img src="../../assets/img/buyer-my-lan-01.png" v-if="index == 1">
						<img src="../../assets/img/buyer-my-hui-01.png" v-else>
						<text>{{ $t('seller.home.products') }}</text>
					</div>
					<div class="leftitem" @click="toHistory" :class="index == 2? 'dtbj': ''">
						<img src="../../assets/img/buyer-my-lan-02.png" v-if="index == 2">
						<img src="../../assets/img/buyer-my-hui-02.png" v-else>
						<text>{{ $t('buyer.purchaseHistory.purchaseHistory') }}</text>
					</div>
					<div class="leftitem" @click="toSent" :class="index == 3? 'dtbj': ''">
						<img src="../../assets/img/buyer-my-lan-04.png" v-if="index == 3">
						<img src="../../assets/img/buyer-my-hui-04.png" v-else>
						<text>{{ $t('buyer.my.sentRefundRequest') }}</text>
					</div>
					<div class="leftitem" @click="toConversations" :class="index == 5? 'dtbj': ''">
						<img src="../../assets/img/buyer-my-lan-06.png" v-if="index == 5">
						<img src="../../assets/img/buyer-my-hui-06.png" v-else>
						<text>{{ $t('buyer.conversations.conversations') }}</text>
						<span class="numbers">{{ count }}</span>
					</div>
					<div class="leftitem" @click="toMyWallet" :class="index == 6? 'dtbj': ''">
						<img src="../../assets/img/buyer-my-lan-07.png" v-if="index == 6">
						<img src="../../assets/img/buyer-my-hui-07.png" v-else>
						<text>{{ $t('buyer.myWallet.myWallet') }}</text>
					</div>
					<div class="leftitem" @click="toManage" :class="index == 8? 'dtbj': ''">
						<img src="../../assets/img/buyer-my-lan-09.png" v-if="index == 8">
						<img src="../../assets/img/buyer-my-hui-09.png" v-else>
						<text>{{ $t('buyer.manageProfile.manageProfile') }}</text>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {buyerInfo,huihtongji} from '@/api/buyerapi'
	export default {
		data() {
			return {
				index: 0, //动态显示组件和图标
				buyerInfo: {}, //买家信息
				count: '', //会话统计
			}
		},
		mounted(){
			this.getbuyerInfo()
			this.gettongji()
		},
		methods: {
			// 获取个人信息
			async getbuyerInfo(){
				const {data: res} = await buyerInfo()
				this.buyerInfo = res.success
				console.log(res)
			},
			async gettongji(){
				const {data: res} = await huihtongji()
				this.count = res.success.count
			},
			// 仪表盘
			toDashboard(){
				this.$router.push('/index/mypage/dashboard')
			},
			// 购买历史
			toHistory(){
				this.$router.push('/index/mypage/purchasehistory')
			},
			// 个人资料管理
			toManage(){
				this.$router.push('/index/mypage/manageprofile')
			},
			// 已发送退款请求
			toSent(){
				this.$router.push('/index/mypage/sentrefundrequest')
			},
			// 愿望清单
			toWishlist(){
				this.$router.push('/index/mypage/wishlist')
			},
			// 我的钱包
			toMyWallet() {
				this.$router.push('/index/mypage/mywallet')
			},
			// 工单
			toSupportTicket() {
				this.$router.push('/index/mypage/supportticket')
			},
			// 对话
			toConversations() {
				this.$router.push('/index/mypage/conversations')
			},
			// 邀请
			toBeaBrushingUser() {
				this.$router.push('/index/mypage/beabrushinguser')
			},
		}
	}
</script>

<style scoped lang="less">

	.router-link-active {
		color: #00adf1;
		text-decoration: none;
	}
	a {
		text-decoration: none;
		color: #000;
	}
	.phone {
		.centbox {
			width: 100vh;
            height: 100vh;
			overflow: hidden;
            position: fixed;
            z-index: 99999;
            top: 0;
            left: 0;
			display: flex;
            background: #FFFFFF;
			.centleft {
				width: 100%;
				.headimg {
					width: 100%;
					height: auto;
					box-sizing: border-box;
					background-color: #00ADF1;
					display: flex;
                    justify-content: flex-start;
					flex-direction: column;
					align-items: flex-start;
					padding: 40px;
					img {
						width: 180px;
						height: 180px;
						overflow: hidden;
						border-radius: 50%;
						flex-shrink: 0;
					}
					.untext{
						font-size: 52px;
						font-family: Arial;
						color: #FFFFFF;
						margin-top: 10px;
					}
					.zhtext{
						font-size: 40px;
						font-family: Arial;
						color: #D5D7D7;
						margin-top: 3px;
					}
				}
				.leftitembox {
					background-color: #FFFFFF;
					padding-top: 25px;
					padding-bottom: 25px;
					.dtbj{
						background-color: #E4F7FF;
					}
					.leftitem {
						width: 100%;
						border-radius: 5px;
						box-sizing: border-box;
						margin: 0 10px;
						display: flex;
						align-items: center;
						padding-left: 20px;
						img {
							width: 50px;
							height: 50px;
						}
						text {
							font-size: 40px;
							font-family: Arial;
							font-weight: 400;
							color: #969696;
							line-height: 100px;
							margin-left: 20px;
						}
						.numbers {
							margin-left: 20px;
							display: inline-block;
							padding: 2px 20px;
							border-radius: 6px;
							margin-top: 5px;
							background: #0abb75;
							color: #FFFFFF;
							margin-bottom: 5px;
							font-size: 32px;
						}
					}
				}
				
			}
		}
	}
</style>
