import request from "@/utils/request";

//const baseURL = 'http://u-api.jdc5g.com' //测试地址
const baseURL = 'https://api.baitidiscounts.com' //正式地址

// 图片上传
export const uploadURL = baseURL + '/uploaded/upload-img'

// 买家注册
export const buyerReg = (data) => {
    return request({
        url: "/v1/buyer-reg",
        method: 'POST',
        data
    })
}

// 卖家注册
export const sellerReg = (data) => {
    return request({
        url: "/v1/seller-reg",
        method: 'POST',
        data
    })
}

// 登录
export const login = (data) => {
    return request({
        url: "/v1/login",
        method: 'POST',
        data
    })
}

// 语言列表
export const language = () => {
    return request({
        url: "/v1/p/language",
        method: 'POST'
    })
}

// 网站页脚
export const footInfo = () => {
    return request({
        url: "/v1/p/foot-info",
        method: 'POST'
    })
}

// 支付方式
export const paymentMethod = () => {
    return request({
        url: "/v1/p/payment-method",
        method: 'POST'
    })
}