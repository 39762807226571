<template>
    <div class="pc">
        <div class="bigbox">
            <div class="summary">
                <div class="titles">
                    <span>{{ $t('seller.order.orderId') }}:</span>
                    <span>{{ orderSummary.order_code }}</span>
                </div>
                <div class="orderhuizong">
                    <div class="huiztitle">
                        <span>{{ $t('buyer.orderDetail.orderSummary') }}</span>
                    </div>
                    <div class="twobox">
                        <div class="itemboxs">
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.orderCode') }}:</span>
                                <span>{{ orderSummary.order_code }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.customer') }}:</span>
                                <span>{{ orderSummary.customer }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.email') }}:</span>
                                <span>{{ orderSummary.email }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.address') }}:</span>
                                <span>{{ orderSummary.shipping_address }}</span>
                            </div>
                        </div>
                        <div class="itemboxs">
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.date') }}:</span>
                                <span>{{ orderSummary.order_date }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.orderStatus') }}:</span>
                                <span>{{ orderSummary.order_status }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.totalOrderAmount') }}:</span>
                                <span>{{ orderSummary.total_order_amount }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.ShippingMethod') }}:</span>
                                <span>{{ orderSummary.shipping_method }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.paymentMethod') }}:</span>
                                <span>{{ orderSummary.payment_method }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('seller.orderDetail.additionalInfo') }}:</span>
                                <span>{{ orderSummary.additional_info }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="details">
                <div class="leftbox">
                    <div class="titletext">
                        <span>{{ $t('buyer.orderDetail.orderDetail') }}</span>
                    </div>
                    <div class="tablebox">
                        <el-table :data="orderLists" style="width: 100%">
                            <el-table-column prop="" type="index" label="#" width="40"/>
                            <el-table-column prop="goods_name" :label="$t('seller.product.productName')" width="310">
                                <template #default="scope">
                                    <span class="span1" @click="toShopDetail(scope.row.id)">{{ scope.row.goods_name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="count" :label="$t('buyer.orderDetail.quantity')" width="90"/>
                            <el-table-column prop="delivery_type" :label="$t('buyer.orderDetail.deliveryType')" width="130"/>
                            <el-table-column prop="total_price" :label="$t('buyer.orderDetail.price')" width="90"/>
                            <el-table-column prop="refund_seller_status_str" :label="$t('buyer.orderDetail.refundStatus')" width="140">
                                <template #default="scope">
                                    <span class="span2" v-if="scope.row.refund_seller_status == 4" @click="toSend(scope.row)">{{'Refund'}}</span>
                                    <span class="span2" v-else>{{scope.row.refund_seller_status_str}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="rightbox">
                    <div class="ammiunt">
                        <div class="ordertitle">{{ $t('buyer.orderDetail.orderAmount') }}</div>
                        <div class="orderitem">
                            <div class="itembox">
                                <span>{{ $t('buyer.orderDetail.subtotal') }}</span>
                                <span>{{ orderAmmount.subtotal }}</span>
                            </div>
                            <div class="itembox">
                                <span>{{ $t('buyer.orderDetail.shipping') }}</span>
                                <span>{{ orderAmmount.shipping }}</span>
                            </div>
                            <div class="itembox">
                                <span>{{ $t('buyer.orderDetail.tax') }}</span>
                                <span>{{ orderAmmount.tax }}</span>
                            </div>
                            <div class="itembox">
                                <span>{{ $t('buyer.orderDetail.coupons') }}</span>
                                <span>{{ orderAmmount.coupon }}</span>
                            </div>
                            <div class="itembox">
                                <span>{{ $t('seller.orderDetail.total') }}</span>
                                <span>{{ orderAmmount.total }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="make" @click="showpaytc">
                        <span>Make Crypto Payment</span>
                    </div>
                    <span class="or">Or</span>
                    <div class="balance">
                        <span>Your wallet balance : $99,864.86</span>
                    </div>
                    <div class="pays">
                        <span>Pay with wallet</span>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="paytc" v-show="showtc">
            <div class="tchezi">
                <div class="paytitle">
                    <span>Crypto Wallet Recharge</span>
                </div>
                <div class="payselectbox">
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>USDT(TRC20)</span>
                    </div>
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>USDT(ERC20)</span>
                    </div>
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>BTC</span>
                    </div>
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>BTC</span>
                    </div>
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>BTC</span>
                    </div>
                </div>
                <div class="zhifuma">
                    <div class="adresbox">
                        <span>Address</span>
                        <span>Click Copy</span>
                        <span>TLcH6fmoFNAfDg4q98NPQGSovoBpRfE3Xm</span>
                    </div>
                    <div class="qrcode">
                        <span class="qrtext">QrCode</span>
                        <div class="ewmbox">
                            <div class="erweima">
                                <span>only supports Tron assets (TRC10/TRC20)</span>
                                <img src="../../assets/img/erweima.png" />
                            </div>
                            <div class="addrbox">
                                <span>Wallet Address</span>
                                <span>TLcH6fmoFNAfDg4q98NPQGSovoB pRfE3Xm</span>
                            </div>
                        </div>
                        <div class="miaosu">
                            <span>Description</span>
                            <input type="text" placeholder="After payment, please contact customer service to confirm">
                        </div>
                    </div>
                </div>
                <div class="amountbox">
                    <div>
                        <span>Amount</span>
                        <span>*</span>
                    </div>
                    <input type="number" placeholder="After payment, please contact customer service to confirm">
                </div>
                <div class="qrbtn">
                    <div class="querenbox">
                        <span>Confirm</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="bigbox">
            <div class="summary">
                <div class="titles">
                    <span>{{ $t('seller.order.orderId') }}:</span>
                    <span>{{ orderSummary.order_code }}</span>
                </div>
                <div class="orderhuizong">
                    <div class="huiztitle">
                        <span>{{ $t('buyer.orderDetail.orderSummary') }}</span>
                    </div>
                    <div class="twobox">
                        <div class="itemboxs">
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.orderCode') }}:</span>
                                <span>{{ orderSummary.order_code }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.customer') }}:</span>
                                <span>{{ orderSummary.customer }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.email') }}:</span>
                                <span>{{ orderSummary.email }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.address') }}:</span>
                                <span>{{ orderSummary.shipping_address }}</span>
                            </div>
                        </div>
                        <div class="itemboxs">
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.date') }}:</span>
                                <span>{{ orderSummary.order_date }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.orderStatus') }}:</span>
                                <span>{{ orderSummary.order_status }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.totalOrderAmount') }}:</span>
                                <span>{{ orderSummary.total_order_amount }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.ShippingMethod') }}:</span>
                                <span>{{ orderSummary.shipping_method }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('buyer.orderDetail.paymentMethod') }}:</span>
                                <span>{{ orderSummary.payment_method }}</span>
                            </div>
                            <div class="itemtext">
                                <span>{{ $t('seller.orderDetail.additionalInfo') }}:</span>
                                <span>{{ orderSummary.additional_info }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="details">
                <div class="leftbox">
                    <div class="titletext">
                        <span>{{ $t('buyer.orderDetail.orderDetail') }}</span>
                    </div>
                    <div class="tablebox">
                        <el-table :data="orderLists" style="width: 100%" :style="{fontSize: '24px'}">
                            <el-table-column prop="" type="index" label="#" width="40"/>
                            <el-table-column prop="goods_name" :label="$t('seller.product.productName')" width="400">
                                <template #default="scope">
                                    <span class="span1" @click="toShopDetail(scope.row.id)">{{ scope.row.goods_name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="count" :label="$t('buyer.orderDetail.quantity')" width="120"/>
                            <el-table-column prop="delivery_type" :label="$t('buyer.orderDetail.deliveryType')" width="140"/>
                            <el-table-column prop="total_price" :label="$t('buyer.orderDetail.price')" width="90"/>
                            <el-table-column prop="refund_seller_status_str" :label="$t('buyer.orderDetail.refundStatus')" width="150">
                                <template #default="scope">
                                    <span class="span2" v-if="scope.row.refund_seller_status == 4" @click="toSend(scope.row)">{{'Refund'}}</span>
                                    <span class="span2" v-else>{{scope.row.refund_seller_status_str}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="rightbox">
                    <div class="ammiunt">
                        <div class="ordertitle">{{ $t('buyer.orderDetail.orderAmount') }}</div>
                        <div class="orderitem">
                            <div class="itembox">
                                <span>{{ $t('buyer.orderDetail.subtotal') }}</span>
                                <span>{{ orderAmmount.subtotal }}</span>
                            </div>
                            <div class="itembox">
                                <span>{{ $t('buyer.orderDetail.shipping') }}</span>
                                <span>{{ orderAmmount.shipping }}</span>
                            </div>
                            <div class="itembox">
                                <span>{{ $t('buyer.orderDetail.tax') }}</span>
                                <span>{{ orderAmmount.tax }}</span>
                            </div>
                            <div class="itembox">
                                <span>{{ $t('buyer.orderDetail.coupons') }}</span>
                                <span>{{ orderAmmount.coupon }}</span>
                            </div>
                            <div class="itembox">
                                <span>{{ $t('seller.orderDetail.total') }}</span>
                                <span>{{ orderAmmount.total }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="make" @click="showpaytc">
                        <span>Make Crypto Payment</span>
                    </div>
                    <span class="or">Or</span>
                    <div class="balance">
                        <span>Your wallet balance : $99,864.86</span>
                    </div>
                    <div class="pays">
                        <span>Pay with wallet</span>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <!-- <div class="paytc" v-show="showtc">
            <div class="tchezi">
                <div class="paytitle">
                    <span>Crypto Wallet Recharge</span>
                </div>
                <div class="payselectbox">
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>USDT(TRC20)</span>
                    </div>
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>USDT(ERC20)</span>
                    </div>
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>BTC</span>
                    </div>
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>BTC</span>
                    </div>
                    <div class="payselect">
                        <img src="../../assets/img/tether.png" />
                        <span>BTC</span>
                    </div>
                </div>
                <div class="zhifuma">
                    <div class="adresbox">
                        <span>Address</span>
                        <span>Click Copy</span>
                        <span>TLcH6fmoFNAfDg4q98NPQGSovoBpRfE3Xm</span>
                    </div>
                    <div class="qrcode">
                        <span class="qrtext">QrCode</span>
                        <div class="ewmbox">
                            <div class="erweima">
                                <span>only supports Tron assets (TRC10/TRC20)</span>
                                <img src="../../assets/img/erweima.png" />
                            </div>
                            <div class="addrbox">
                                <span>Wallet Address</span>
                                <span>TLcH6fmoFNAfDg4q98NPQGSovoB pRfE3Xm</span>
                            </div>
                        </div>
                        <div class="miaosu">
                            <span>Description</span>
                            <input type="text" placeholder="After payment, please contact customer service to confirm">
                        </div>
                    </div>
                </div>
                <div class="amountbox">
                    <div>
                        <span>Amount</span>
                        <span>*</span>
                    </div>
                    <input type="number" placeholder="After payment, please contact customer service to confirm">
                </div>
                <div class="qrbtn">
                    <div class="querenbox">
                        <span>Confirm</span>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import {orderDetails} from "@/api/buyerapi"
import { ElMessage } from 'element-plus'
export default {
    data() {
        return {
            showtc: false, //支付弹窗
            id: '', //订单id
            orderSummary: {}, //订单汇总
            orderLists: [], //订单详情列表
            orderAmmount: {}, //订单数量
            tableData: [
                {
                    product: "sauiywuiqyeuiwqyeuiqwyeiwquieyqueuqywequieyuiqweyuiqwu",
                    variation: "5",
                    quantity: "4",
                    deliverytype: "home delivery",
                    price: "$30.99",
                    refudstatus: "Send",
                    review: "not delivered yet",
                    id: "456",
                },
            ],
        };
    },
    mounted(){
        this.id = this.$route.query.id
        this.getOrderDetail()
    },
    methods: {
        // 获取订单详情
        async getOrderDetail(){
            const {data:res} = await orderDetails({
                id: this.id
            })
            if(res.status == 200) {
                console.log(res)
                this.orderSummary = res.success.order_summary
                this.orderLists = res.success.order_goods_list
                this.orderAmmount = res.success.order_ammount
            }else {
                ElMessage.error(res.message)
            }
        },
        // 去发送退款请求
        toSend(e) {
            this.$router.push({path: "/index/mypage/sendrefundrequest",query:{id: e.id, price: e.total_price,name: e.goods_name}});
        },
        // 显示支付弹窗
        showpaytc() {
            this.showtc = true
        },
        // 去商品详情页
        toShopDetail(e) {
            this.$router.push('/index/shopdetail')
        }
    },
};
</script>

<style scoped lang="less">

    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}

    .pc {
        .bigbox {
            width: 1080px;

            .summary {
                .titles {
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: bold;
                    color: #000000;
                    text-align: left;
                    box-sizing: border-box;
                    margin-bottom: 20px;

                    span:nth-child(2) {
                        font-weight: 400;
                        margin-left: 10px;
                    }
                }

                .orderhuizong {
                    width: 100%;
                    background: #fff;

                    .huiztitle {
                        width: 100%;
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 36px;
                        box-sizing: border-box;
                        text-align: left;
                        border-bottom: solid 1px #cacaca;
                        padding-left: 30px;
                    }

                    .twobox {
                        width: 100%;
                        padding: 0 30px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: space-between;

                        .itemboxs {
                            width: 46%;

                            .itemtext {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                height: 60px;

                                span:nth-child(1) {
                                    display: inline-block;
                                    text-align: left;
                                    white-space: nowrap;
                                }

                                span:nth-child(2) {
                                    display: inline-block;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }

            .details {
                width: 100%;
                margin-top: 25px;
                display: flex;
                justify-content: space-between;

                .leftbox {
                    width: 800px;
                    background: #fff;

                    .titletext {
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 42px;
                        border-bottom: solid 1px #cacaca;
                        text-align: left;
                        padding-left: 30px;
                    }

                    .tablebox {
                        text-align: left;

                        .span1 {
                            color: #00adf1;
                            cursor: pointer;
                        }

                        .span2 {
                            display: inline-block;
                            padding: 0 10px;
                            height: 23px;
                            background: #00adf1;
                            border-radius: 4px;
                            font-size: 14px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #ffffff;
                            cursor: pointer;
                        }
                    }
                }

                .rightbox {
                    width: 250px;

                    .ammiunt {
                        width: 100%;
                        background: #fff;

                        .ordertitle {
                            font-size: 18px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #000000;
                            line-height: 42px;
                            border-bottom: solid 1px #cacaca;
                            text-align: left;
                            padding-left: 30px;
                        }

                        .orderitem {
                            width: 100%;
                            padding: 0 30px;
                            box-sizing: border-box;

                            .itembox {
                                display: flex;
                                justify-content: space-between;
                                height: 45px;
                                line-height: 45px;
                            }
                        }
                    }

                    .make {
                        width: 252px;
                        height: 50px;
                        background: #00adf1;
                        border-radius: 5px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 20px;
                        cursor: pointer;
                    }

                    .or {
                        display: block;
                        width: 75px;
                        height: 26px;
                        background: #ffffff;
                        border-radius: 5px;
                        text-align: center;
                        line-height: 26px;
                        margin: auto;
                        margin-top: 15px;
                        margin-bottom: 20px;
                    }

                    .balance {
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 36px;
                    }

                    .pays {
                        width: 252px;
                        height: 50px;
                        background: #00adf1;
                        border-radius: 5px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        .paytc {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99999;

            .tchezi {
                width: 780px;
                height: 650px;
                overflow: auto;
                margin: auto;
                margin-top: 100px;
                background: #fff;
                border-radius: 5px;
                box-sizing: 15px;
                padding: 0 15px;

                .paytitle {
                    width: 100%;
                    height: 47px;
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 500;
                    color: #000000;
                    line-height: 47px;
                    text-align: left;
                    border-bottom: 1px solid #DCDCDC;
                }

                .payselectbox {
                    width: 750px;
                    height: 320px;
                    display: flex;
                    flex-wrap: wrap;
                    box-sizing: border-box;
                    padding-top: 25px;

                    .payselect {
                        width: 150px;
                        height: 120px;
                        border: 1px solid #00ADF1;
                        border-radius: 5px;
                        font-size: 12px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 0 10px;
                        cursor: pointer;

                        img {
                            width: 100px;
                            height: 80px;
                        }
                    }
                }

                .zhifuma {
                    width: 750px;
                    border: 1px solid #DCDCDC;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 30px 15px;

                    .adresbox {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;

                        span:nth-child(2) {
                            width: 110px;
                            height: 35px;
                            background: #00ADF1;
                            border-radius: 5px;
                            line-height: 35px;
                            color: #fff;
                        }

                        span:nth-child(3) {
                            width: 530px;
                            height: 35px;
                            border: 1px solid #DCDCDC;
                            border-radius: 5px;
                            line-height: 35px;
                            box-sizing: border-box;
                            padding-left: 30px;
                            text-align: left;
                            color: #969696;
                        }
                    }

                    .qrcode {
                        margin-top: 35px;

                        .qrtext {
                            display: block;
                            width: 100%;
                            font-size: 14px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #000000;
                            line-height: 30px;
                            text-align: left;
                        }

                        .ewmbox {
                            width: 510px;
                            margin-left: 150px;
                            margin-top: 50px;

                            .erweima {
                                &>span {
                                    font-size: 26px;
                                    font-family: Arial;
                                    font-weight: 400;
                                    color: #000000;
                                    line-height: 31px;
                                }

                                &>img {
                                    width: 377px;
                                    height: 377px;
                                    border-radius: 15px;
                                    overflow: hidden;
                                    margin-top: 50px;
                                }
                            }

                            .addrbox {
                                display: flex;
                                flex-direction: column;
                                font-size: 26px;
                                font-family: Arial;
                                font-weight: 400;
                                line-height: 42px;
                                margin-top: 30px;

                                span:nth-child(2) {
                                    color: #969696;
                                }
                            }
                        }

                        .miaosu {
                            display: flex;
                            justify-content: space-between;
                            display: flex;
                            align-items: center;
                            margin-top: 40px;
                            font-size: 14px;

                            input {
                                width: 530px;
                                height: 44px;
                                border: 1px solid #DCDCDC;
                                border-radius: 5px;
                                outline: none;
                                box-sizing: border-box;
                                padding-left: 20px;
                            }
                        }
                    }
                }

                .amountbox {
                    width: 750px;
                    height: 77px;
                    border: 1px solid #DCDCDC;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 20px;
                    box-sizing: border-box;
                    padding: 0 15px;
                    font-size: 14px;

                    span:nth-child(2) {
                        color: #FF0000;
                    }

                    input {
                        width: 530px;
                        height: 44px;
                        border: 1px solid #DCDCDC;
                        border-radius: 5px;
                        outline: none;
                        box-sizing: border-box;
                        padding-left: 20px;
                    }
                }

                .qrbtn {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    .querenbox {
                        width: 215px;
                        height: 50px;
                        background: #00ADF1;
                        border-radius: 5px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 30px;
                        margin-bottom: 30px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }

            }
        }
    }
    .phone {
        .bigbox {
            width: 100%;

            .summary {
                .titles {
                    font-size: 28px;
                    line-height: 60px;
                    font-family: Arial;
                    font-weight: bold;
                    color: #000000;
                    text-align: left;
                    box-sizing: border-box;
                    margin-bottom: 20px;
                    padding-left: 30px;
                    width: 80%;
                    span:nth-child(2) {
                        font-weight: 400;
                        margin-left: 10px;
                    }
                }

                .orderhuizong {
                    width: 100%;
                    background: #fff;

                    .huiztitle {
                        width: 100%;
                        font-size: 28px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 60px;
                        box-sizing: border-box;
                        text-align: left;
                        border-bottom: solid 1px #cacaca;
                        padding-left: 30px;
                    }

                    .twobox {
                        width: 100%;
                        padding: 0 30px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .itemboxs {
                            width: 100%;
                            box-sizing: border-box;
                            .itemtext {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                height: 60px;
                                font-size: 24px;
                                span:nth-child(1) {
                                    display: inline-block;
                                    text-align: left;
                                    white-space: nowrap;
                                }

                                span:nth-child(2) {
                                    display: inline-block;
                                    text-align: right;
                                    width: 75%;
                                }
                            }
                        }
                    }
                }
            }

            .details {
                width: 100%;
                margin-top: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .leftbox {
                    width: 100%;
                    background: #fff;
                    .titletext {
                        font-size: 28px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 60px;
                        border-bottom: solid 1px #cacaca;
                        text-align: left;
                        padding-left: 30px;
                    }

                    .tablebox {
                        text-align: left;

                        .span1 {
                            color: #00adf1;
                        }

                        .span2 {
                            display: inline-block;
                            padding: 5px 10px;
                            height: 23px;
                            background: #00adf1;
                            border-radius: 5px;
                            font-size: 24px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #ffffff;
                        }
                    }
                }

                .rightbox {
                    width: 100%;

                    .ammiunt {
                        width: 100%;
                        background: #fff;

                        .ordertitle {
                            font-size: 28px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #000000;
                            line-height: 60px;
                            border-bottom: solid 1px #cacaca;
                            text-align: left;
                            padding-left: 30px;
                        }

                        .orderitem {
                            width: 100%;
                            padding: 0 30px;
                            box-sizing: border-box;

                            .itembox {
                                display: flex;
                                justify-content: space-between;
                                height: 45px;
                                line-height: 45px;
                                font-size: 24px;
                            }
                        }
                    }

                    .make {
                        width: 252px;
                        height: 50px;
                        background: #00adf1;
                        border-radius: 5px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 20px;
                        cursor: pointer;
                    }

                    .or {
                        display: block;
                        width: 75px;
                        height: 26px;
                        background: #ffffff;
                        border-radius: 5px;
                        text-align: center;
                        line-height: 26px;
                        margin: auto;
                        margin-top: 15px;
                        margin-bottom: 20px;
                    }

                    .balance {
                        font-size: 18px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 36px;
                    }

                    .pays {
                        width: 252px;
                        height: 50px;
                        background: #00adf1;
                        border-radius: 5px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #ffffff;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        .paytc {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99999;

            .tchezi {
                width: 780px;
                height: 650px;
                overflow: auto;
                margin: auto;
                margin-top: 100px;
                background: #fff;
                border-radius: 5px;
                box-sizing: 15px;
                padding: 0 15px;

                .paytitle {
                    width: 100%;
                    height: 47px;
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 500;
                    color: #000000;
                    line-height: 47px;
                    text-align: left;
                    border-bottom: 1px solid #DCDCDC;
                }

                .payselectbox {
                    width: 750px;
                    height: 320px;
                    display: flex;
                    flex-wrap: wrap;
                    box-sizing: border-box;
                    padding-top: 25px;

                    .payselect {
                        width: 150px;
                        height: 120px;
                        border: 1px solid #00ADF1;
                        border-radius: 5px;
                        font-size: 12px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #000000;
                        line-height: 30px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 0 10px;
                        cursor: pointer;

                        img {
                            width: 100px;
                            height: 80px;
                        }
                    }
                }

                .zhifuma {
                    width: 750px;
                    border: 1px solid #DCDCDC;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 30px 15px;

                    .adresbox {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;

                        span:nth-child(2) {
                            width: 110px;
                            height: 35px;
                            background: #00ADF1;
                            border-radius: 5px;
                            line-height: 35px;
                            color: #fff;
                        }

                        span:nth-child(3) {
                            width: 530px;
                            height: 35px;
                            border: 1px solid #DCDCDC;
                            border-radius: 5px;
                            line-height: 35px;
                            box-sizing: border-box;
                            padding-left: 30px;
                            text-align: left;
                            color: #969696;
                        }
                    }

                    .qrcode {
                        margin-top: 35px;

                        .qrtext {
                            display: block;
                            width: 100%;
                            font-size: 14px;
                            font-family: Arial;
                            font-weight: 400;
                            color: #000000;
                            line-height: 30px;
                            text-align: left;
                        }

                        .ewmbox {
                            width: 510px;
                            margin-left: 150px;
                            margin-top: 50px;

                            .erweima {
                                &>span {
                                    font-size: 26px;
                                    font-family: Arial;
                                    font-weight: 400;
                                    color: #000000;
                                    line-height: 31px;
                                }

                                &>img {
                                    width: 377px;
                                    height: 377px;
                                    border-radius: 15px;
                                    overflow: hidden;
                                    margin-top: 50px;
                                }
                            }

                            .addrbox {
                                display: flex;
                                flex-direction: column;
                                font-size: 26px;
                                font-family: Arial;
                                font-weight: 400;
                                line-height: 42px;
                                margin-top: 30px;

                                span:nth-child(2) {
                                    color: #969696;
                                }
                            }
                        }

                        .miaosu {
                            display: flex;
                            justify-content: space-between;
                            display: flex;
                            align-items: center;
                            margin-top: 40px;
                            font-size: 14px;

                            input {
                                width: 530px;
                                height: 44px;
                                border: 1px solid #DCDCDC;
                                border-radius: 5px;
                                outline: none;
                                box-sizing: border-box;
                                padding-left: 20px;
                            }
                        }
                    }
                }

                .amountbox {
                    width: 750px;
                    height: 77px;
                    border: 1px solid #DCDCDC;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 20px;
                    box-sizing: border-box;
                    padding: 0 15px;
                    font-size: 14px;

                    span:nth-child(2) {
                        color: #FF0000;
                    }

                    input {
                        width: 530px;
                        height: 44px;
                        border: 1px solid #DCDCDC;
                        border-radius: 5px;
                        outline: none;
                        box-sizing: border-box;
                        padding-left: 20px;
                    }
                }

                .qrbtn {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    .querenbox {
                        width: 215px;
                        height: 50px;
                        background: #00ADF1;
                        border-radius: 5px;
                        line-height: 50px;
                        text-align: center;
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 30px;
                        margin-bottom: 30px;
                        margin-right: 10px;
                        cursor: pointer;
                    }
                }

            }
        }
    }
</style>