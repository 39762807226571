<template>
    <div>
        <div class="bigbox">
            <div class="titlebox">
                <span>{{ $t('buyer.my.wishList') }}</span>
            </div>
            <div class="qdbox">
                <div class="itemcp" v-for="(item, index) in 15" :key="index">
                    <!-- <img src="../../assets/img/shouji.png" @click="toShopDetail" mode="" /> -->
                    <div class="jiage">
                        <text>$180</text>
                        <el-rate v-model="xingxing" disabled />
                        <div class="mswenz" @click="toShopDetail">
                            <span>totes Men's Cirrus Chelsea Ankle Rain Boottotes Cirrus Chel</span>
                        </div>
                    </div>
                    <div class="dbbox">
                        <img src="../../assets/img/shanchu-lan.png" alt="" />
                        <!-- <div class="addCart">
                        <img src="../../assets/img/cart-bai.png" alt="" />
                        <span>Add to cart</span>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="fenye">
                <el-pagination background layout="prev, pager, next" :total="1000" />
            </div>
        </div>

        <!-- 添加商品第二个弹窗 -->
        <!-- <div class="cgzhezhao" v-show="showtwo">
            <div class="successTc">
            <div class="cuowu">
            <img src="../../assets/img/cuowu-hui.png" alt="" />
            </div>
            <div class="chenggongbox">
            <img src="../../assets/img/zhengque-lan.png" alt="" />
            <span>ltem added to your cart!</span>
            </div>
            <div class="spinfo">
            <div class="leftimg">
                <img src="../../assets/img/diannao.png" alt="" />
            </div>
            <div class="righttext">
                <div class="shopname">
                    <span>Brita Stream Filters, 3 Count (Pack o f1), Grey</span>
                </div>
                <div class="jiagebox">
                    <span>Price:</span>
                    <span>$584.00</span>
                </div>
            </div>
            </div>
            <div class="botmbox">
                <div class="backtext">
                    <span>Back to shopping</span>
                </div>
                <div class="proceedbox">
                    <span>Proceed to Checkout</span>
                </div>
            </div>
        </div>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            xingxing: 2, //星星数量
            showtwo: false, //弹窗2
        }
    },
    methods: {
        // 去详情页
        toShopDetail() {
            this.$router.push('/index/shopdetail')
        },
    },
}
</script>

<style scoped lang="less">
.bigbox {
    width: 1080px;

    .titlebox {
        font-size: 18px;
        font-family: Arial;
        font-weight: bold;
        color: #000000;
        text-align: left;
    }
}

.qdbox {
    width: 1080px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .itemcp {
        width: 200px;
        height: 360px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        background: #fff;

        & > img {
            width: 200px;
            height: 200px;
            cursor: pointer;
        }

        .jiage {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;
            box-sizing: border-box;
            padding-left: 10px;

            & > text {
                font-size: 14px;
                font-family: Arial;
                font-weight: bold;
                color: #53bef4;
            }

            .mswenz {
                width: 100%;
                height: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                font-size: 12px;
                -webkit-box-orient: vertical;
                text-align: left;
                cursor: pointer;
            }
        }

        .dbbox {
            width: 100%;
            height: 60px;
            border-top: solid 1px #ebebeb;
            display: flex;
            align-items: center;
            justify-content: space-around;

            & > img {
                width: 22px;
                height: 24px;
                cursor: pointer;
            }

            .addCart {
                width: 150px;
                height: 34px;
                background: #00adf1;
                border-radius: 4px;
                background: #00adf1;
                color: #fff;
                font-size: 14px;
                font-family: Arial;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                & > img {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.fenye {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cgzhezhao {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.6);
    box-sizing: border-box;

    .successTc {
        width: 700px;
        background: #ffffff;
        border-radius: 5px;
        margin: auto;
        margin-top: 200px;

        .cuowu {
            width: 100%;
            height: 50px;
            position: relative;

            img {
                width: 20px;
                height: 20px;
                position: absolute;
                right: 25px;
                top: 15px;
                cursor: pointer;
            }
        }

        .chenggongbox {
            img {
                width: 80px;
                height: 80px;
                margin-bottom: 25px;
            }

            font-size: 24px;
            font-family: Arial;
            font-weight: 500;
            color: #000000;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .spinfo {
            width: 600px;
            height: 180px;
            border-top: solid 1px #e6e6e6;
            border-bottom: solid 1px #e6e6e6;
            margin: auto;
            margin-top: 30px;
            display: flex;
            align-items: center;

            .leftimg {
                img {
                    width: 113px;
                    height: 113px;
                }
            }

            .righttext {
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .shopname {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: 30px;
                    width: 420px;
                    text-align: left;
                    overflow: hidden;
                    /*line-height: 15px;*/
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    /*重新定义-webkit私有盒模型*/
                    -webkit-box-orient: vertical;
                    /*内容之间的排列顺序，vertical垂直方向排列*/
                    -webkit-line-clamp: 2;
                    /*在第几行末尾显示省略号...*/
                    word-break: break-all;
                }

                .jiagebox {
                    margin-top: 25px;

                    span:nth-child(1) {
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #969696;
                        margin-right: 10px;
                    }

                    span:nth-child(2) {
                        font-size: 24px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #04adf1;
                    }
                }
            }
        }

        .botmbox {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;

            .backtext {
                width: 180px;
                height: 40px;
                border: solid 1px #00adf1;
                color: #00adf1;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                cursor: pointer;
            }

            .proceedbox {
                width: 180px;
                height: 40px;
                background: #00adf1;
                color: #fff;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
}
</style>