<template>
 <!-- 发票详情 -->
    <div class='bodys'>
        <div class="allbox">
            <div class="topname">
                <span>三德阿三 #21656621</span>
                <div class="userbox">
                    <span>xxBuyer 2023-06-30 17:19:29</span>
                    <span>Pending</span>
                </div>
            </div>
            <textarea name="" id=""></textarea>
            <div class="choosebox">
                <el-upload
                    v-model:file-list="fileList"
                    class="upload-demo"
                    action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
                    multiple
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :limit="3"
                    :on-exceed="handleExceed"
                    list-type="picture"
                >
                    <span>Browse</span>
                    <span>{{ $t('seller.addYourProduct.chooseImage') }}</span>
                </el-upload>
            </div>
            <div class="sendbox">
                <span>Send Reply</span>
            </div>
            <div class="huihuabox" v-for="(item,index) in 2" :key="index">
                <div class="tophead">
                    <img src="../../../assets/img/touxiang.jpg">
                    <div class="uname">
                        <span>三德阿瑟 #2656525451</span>
                        <span>xxBuyer 2023-06-30 17:20:20</span>
                    </div>
                </div>
                <div class="textbox">
                    <span>Hello,this is atter sallk serve Plalse contat online cutors service it you have any questions</span>
                </div>
                <div class="span1">
                    <span>download</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            
        }
    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
    .bodys {
        width: 100%;
        box-sizing: border-box;
        padding: 0 25px;
        .allbox {
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 5px;
            margin-top: 30px;
            box-sizing: border-box;
            padding: 0 30px;
            .topname {
                text-align: left;
                width: 100%;
                height: 100px;
                border-bottom: solid 1px #DFDFDF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &>span {
                    font-size: 24px;
                    font-weight: 800;
                }
                .userbox {
                    font-size: 18px;
                    color: #969696;
                    display: flex;
                    align-items: center;
                    margin-top: 6px;
                    span:nth-child(2){
                        display: inline-block;
                        padding: 4px 10px;
                        border-radius: 4px;
                        color: #FFFFFF;
                        background: #969696;
                        font-size: 14px;
                        margin-left: 20px;
                    }
                }
            }
            textarea {
                width: 100%;
                height: 410px;
                outline: none;
                resize: none;
                border: 1px solid #DFDFDF;
                box-sizing: border-box;
                font-size: 16px;
                padding: 15px;
                border-radius: 5px;
                margin-top: 30px;
            }
            .choosebox {
                margin-top: 30px;
                :deep(.el-upload) {
                            width: 100%;
                            height: 50px;
                            border: 1px solid #DFDFDF;
                            border-radius: 4px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            span:nth-child(1){
                                width: 80px;
                                height: 50px;
                                line-height: 50px;
                                background: #DCDCDC;
                                border-radius: 4px;
                            }
                            span:nth-child(2){
                                margin-left: 20px;
                                color: #969696;
                            }
                        }

            }
            .sendbox {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: flex-end;
                cursor: pointer;
                span {
                    display: inline-block;
                    width: 245px;
                    height: 50px;
                    background: #00ADF1;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 50px;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-top: 25px;
                }
            }
            .huihuabox {
                width: 100%;
                min-height: 200px;
                border-bottom: solid 1px #DCDCDC;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .tophead {
                    display: flex;
                    align-items: center;
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-left: 10px;
                    }
                    .uname {
                        display: flex;
                        flex-direction: column;
                        margin-left: 20px;
                        span:nth-child(1) {
                            font-size: 24px;
                        }
                        span:nth-child(2){
                            font-size: 18px;
                            color: #969696;
                        }
                    }
                }
                .textbox {
                    width: 100%;
                    margin-top: 30px;
                    text-align: left;
                }
                .span1 {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 20px;
                    span {
                        background: #969696;
                        border-radius: 4px;
                        color: #FFFFFF;
                        font-size: 14px;
                        padding: 4px 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>