<template>
    <!-- 提现 -->
    <div class="pc">
        <div class="bigbox">
            <div class="titles">
                <span>{{ $t('seller.home.moneyWithdraw') }}</span>
            </div>
            <div class="walletbox">
                <div class="itemwall">
                    <img src="../../../assets/img/seller-money-lan.png">
                    <div class="moneywz">
                        <span>{{ pendingBalance }}</span>
                        <span>{{ $t('seller.home.moneyWithdraw') }}</span>
                    </div>
                </div>
                <div class="itemwalls" @click="tcshow = true">
                    <div class="quanquan">
                        <span></span>
                    </div>
                    <div class="moneywzs">
                        <span>{{ $t('seller.moneyWithdraw.withdrawToWallet') }}</span>
                    </div>
                </div>
                <div class="itemwalls" @click="tcshows = true">
                    <span>+</span>
                    <div class="moneywzs">
                        <span>{{ $t('seller.moneyWithdraw.sendWithdrawRequest') }}</span>
                    </div>
                </div>
            </div>
            <div class="withdbox">
                <div class="tabtitle">
                    <span>{{ $t('seller.moneyWithdraw.withdrawRequestHistory') }}</span>
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column type="index" label="#" width="100" />
                        <el-table-column prop="created_at" :label="$t('seller.appliedRefundRequest.date')" width="220" />
                        <el-table-column prop="recharge_blance" :label="$t('seller.appliedRefundRequest.amount')" width="140" />
                        <el-table-column prop="payment_type" :label="$t('seller.sendWithdrawRequest.paymentType')" width="200" />
                        <el-table-column prop="payment_address" :label="$t('seller.sendWithdrawRequest.paymentAddress')" width="340" />
                        <el-table-column prop="status" :label="$t('seller.commissionHistory.status')" width="110">
                            <template #default="scope">
                                <span class="span1" v-if="scope.row.status_color == 1">{{ scope.row.status }}</span>
                                <span class="span2" v-if="scope.row.status_color == 2">{{ scope.row.status }}</span>
                                <span class="span3" v-if="scope.row.status_color == 3">{{ scope.row.status }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="reject_reason" :label="$t('seller.loanQuotaRequestHistories.rejectReason')" width="180" />
                        <el-table-column prop="sessage" :label="$t('seller.moneyWithdraw.message')" width="220" />
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
            <!-- 提现到余额弹窗 -->
            <div class="tcbox" v-if="tcshow">
                <div class="centbox">
                    <div class="titlebox">
                        <span>{{ $t('seller.moneyWithdraw.withdrawToWallet') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="guanbiTcshow">
                    </div>
                    <div class="cents">
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('buyer.orderDetail.balance') }}</span>
                            </div>
                            <input type="text" disabled v-model="pendingBalance">
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.appliedRefundRequest.amount') }}</span>
                                <span>*</span>
                            </div>
                            <input type="number" v-model="amount" :placeholder="$t('seller.appliedRefundRequest.amount')">
                        </div>
                    </div>
                    <div class="sendbox">
                        <span @click="sendExtract">{{ $t('seller.withdrawToWallet.send') }}</span>
                    </div>
                </div>
            </div>
            <!-- 提现到外部弹窗 -->
            <div class="tcbox" v-if="tcshows">
                <div class="centbox">
                    <div class="titlebox">
                        <span>{{ $t('seller.moneyWithdraw.sendWithdrawRequest') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="guanbiTcshow">
                    </div>
                    <div class="cents">
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('buyer.orderDetail.balance') }}</span>
                            </div>
                            <input type="text" disabled v-model="pendingBalance">
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.appliedRefundRequest.amount') }}</span>
                                <span>*</span>
                            </div>
                            <input type="number" v-model="money" :placeholder="$t('seller.appliedRefundRequest.amount')">
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.sendWithdrawRequest.withdrawMethod') }}</span>
                                <span>*</span>
                            </div>
                            <el-select v-model="selectValue" class="m-2" placeholder="Select" @change="selectChange">
                                <el-option
                                v-for="item in selectList"
                                :key="item.value"
                                :label="item.payment_name"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.makeCryptoPayment.address') }}</span>
                            </div>
                            <input type="text" v-model="cryptoAddress" disabled>
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.moneyWithdraw.message') }}</span>
                            </div>
                            <textarea v-model="message"></textarea>
                        </div>
                    </div>
                    <div class="sendbox">
                        <span @click="sendSellerExteran">{{ $t('seller.withdrawToWallet.send') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="bigbox">
            <div class="titles">
                <span>{{ $t('seller.home.moneyWithdraw') }}</span>
            </div>
            <div class="walletbox">
                <div class="itemwall">
                    <img src="../../../assets/img/seller-money-lan.png">
                    <div class="moneywz">
                        <span>{{ pendingBalance }}</span>
                        <span>{{ $t('seller.home.moneyWithdraw') }}</span>
                    </div>
                </div>
                <div class="itemwalls" @click="tcshow = true">
                    <div class="quanquan">
                        <span></span>
                    </div>
                    <div class="moneywzs">
                        <span>{{ $t('seller.moneyWithdraw.withdrawToWallet') }}</span>
                    </div>
                </div>
                <div class="itemwalls" @click="tcshows = true">
                    <span>+</span>
                    <div class="moneywzs">
                        <span>{{ $t('seller.moneyWithdraw.sendWithdrawRequest') }}</span>
                    </div>
                </div>
            </div>
            <div class="withdbox">
                <div class="tabtitle">
                    <span>{{ $t('seller.moneyWithdraw.withdrawRequestHistory') }}</span>
                </div>
                <div class="tabbox">
                    <el-table :data="tableData" style="width: 100%" :style="{fontSize: '24px'}">
                        <el-table-column prop="created_at" :label="$t('seller.appliedRefundRequest.date')">
                            <template #default="scope">
                                <p>{{ scope.row.created_at }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="recharge_blance" :label="$t('seller.appliedRefundRequest.amount')">
                            <template #default="scope">
                                <p>{{ scope.row.recharge_blance }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="payment_type" :label="$t('seller.sendWithdrawRequest.paymentType')">
                            <template #default="scope">
                                <p>{{ scope.row.payment_type }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="payment_address" :label="$t('seller.sendWithdrawRequest.paymentAddress')">
                            <template #default="scope">
                                <p>{{ scope.row.payment_address }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" :label="$t('seller.commissionHistory.status')">
                            <template #default="scope">
                                <span class="span1" v-if="scope.row.status_color == 1">{{ scope.row.status }}</span>
                                <span class="span2" v-if="scope.row.status_color == 2">{{ scope.row.status }}</span>
                                <span class="span3" v-if="scope.row.status_color == 3">{{ scope.row.status }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="reject_reason" :label="$t('seller.loanQuotaRequestHistories.rejectReason')">
                            <template #default="scope">
                                <p>{{ scope.row.reject_reason }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sessage" :label="$t('seller.moneyWithdraw.message')">
                            <template #default="scope">
                                <p>{{ scope.row.sessage }}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="fenye">
                <el-pagination 
                    background
                    class="my-pagination"
                    layout="prev, pager, next" 
                    :total="count"
                    :default-page-size="10"
                    @prev-click="prevChange"
                    @next-click="nextChange"
                    @current-change="currentChaneg"
                />
            </div>
            <!-- 提现到余额弹窗 -->
            <div class="tcbox" v-if="tcshow">
                <div class="centbox">
                    <div class="titlebox">
                        <span>{{ $t('seller.moneyWithdraw.withdrawToWallet') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="guanbiTcshow">
                    </div>
                    <div class="cents">
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('buyer.orderDetail.balance') }}</span>
                            </div>
                            <input type="text" disabled v-model="pendingBalance">
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.appliedRefundRequest.amount') }}</span>
                                <span>*</span>
                            </div>
                            <input type="number" v-model="amount" :placeholder="$t('seller.appliedRefundRequest.amount')">
                        </div>
                    </div>
                    <div class="sendbox">
                        <span @click="sendExtract">{{ $t('seller.withdrawToWallet.send') }}</span>
                    </div>
                </div>
            </div>
            <!-- 提现到外部弹窗 -->
            <div class="tcbox" v-if="tcshows">
                <div class="centbox">
                    <div class="titlebox">
                        <span>{{ $t('seller.moneyWithdraw.sendWithdrawRequest') }}</span>
                        <img src="../../../assets/img/cuowu-hui.png" @click="guanbiTcshow">
                    </div>
                    <div class="cents">
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('buyer.orderDetail.balance') }}</span>
                            </div>
                            <input type="text" disabled v-model="pendingBalance">
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.appliedRefundRequest.amount') }}</span>
                                <span>*</span>
                            </div>
                            <input type="number" v-model="money" :placeholder="$t('seller.appliedRefundRequest.amount')">
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.sendWithdrawRequest.withdrawMethod') }}</span>
                                <span>*</span>
                            </div>
                            <el-select v-model="selectValue" class="m-2" placeholder="Select" @change="selectChange">
                                <el-option
                                style="font-size: 24px;height: 60px;"
                                v-for="item in selectList"
                                :key="item.value"
                                :label="item.payment_name"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.makeCryptoPayment.address') }}</span>
                            </div>
                            <input type="text" v-model="cryptoAddress" disabled>
                        </div>
                        <div class="itemcent">
                            <div class="leftbox">
                                <span>{{ $t('seller.moneyWithdraw.message') }}</span>
                            </div>
                            <textarea v-model="message"></textarea>
                        </div>
                    </div>
                    <div class="sendbox">
                        <span @click="sendSellerExteran">{{ $t('seller.withdrawToWallet.send') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {pendingBalance,extractList,sellerExtract,sellerExternal,sellerExternalOption} from '@/api/sellerapi'
    import { ElMessage } from 'element-plus'
    export default {
        data () {
            return {
                pendingBalance: '', //可提现金额
                amount: '', //金额
                money: '', //金额
                tcshow: false,
                tcshows: false, //提现到外部弹窗
                page: 1, //页码
                count: 0, //总条数
                tableData:[], //提现记录列表
                selectList: [],
                selectValue: '',//下拉选择的值
                cryptoAddress: '', //选择的地址
                message: '', // 留言
            }
        },
        mounted(){
            this.getMoneyAmount()
            this.getWithdrawalRecords()
            this.getSelectList()
        },
        methods:{
            // 获取可提现金额
            async getMoneyAmount(){
                const {data: res} = await pendingBalance()
                if (res.status == 200) {
                    this.pendingBalance = res.success.pending_balance
                    console.log(res)
                } else {
                    ElMessage.error(res.message)
                }
            },
            // 获取提现记录
            async getWithdrawalRecords(){
                const {data: res} = await extractList({
                    page: this.page
                })
                if (res.status == 200) {
                    this.tableData = res.success.list
                    this.count = parseInt(res.success.count)
                    this.page = res.success.page
                    console.log('提现记录',res)
                } else {
                    ElMessage.error(res.message)
                }
            },
            // 获取下拉列表
            async getSelectList(){
                const {data: res} = await sellerExternalOption()
                if(res.status == 200) {
                    this.selectList = res.success
                    console.log('下拉列表',res)
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 提现到外部
            async sendSellerExteran(){
                let str = this.pendingBalance
                str = str.substring(1)
                str = Number(str)
                if(this.money == '') return ElMessage.error('Please enter the amount!')
                if(this.selectValue == '') return ElMessage.error('Please choose a withdrawal method')
                if(this.money > str) return ElMessage.error('You do not have enough balance to withdraw to wallet')
                const {data: res} = await sellerExternal({
                    "money": this.money, //取钱金额
                    "payment_type":  this.selectValue, //提取类型（Withdraw Method）
                    "messge": this.message //留言信息
                })
                this.tcshows = false
                this.money = ''
                this.message = ''
                this.selectValue = ''
                this.cryptoAddress = ''
                if(res.status == 200) {
                    ElMessage.success(res.message)
                    this.getMoneyAmount()
                    this.getWithdrawalRecords()
                }else {
                    ElMessage.error(res.message)
                }
            },
            // 关闭弹窗
            guanbiTcshow(){
                this.tcshow = false
                this.tcshows = false
                this.amount = ''
                this.money = ''
                this.message = ''
                this.selectValue = ''
                this.cryptoAddress = ''
            },
            // 提现到余额
            async sendExtract(){
                let str = this.pendingBalance
                str = str.substring(1)
                str = Number(str)
                if(this.amount > str) return ElMessage.error('You do not have enough balance to withdraw to wallet')
                if(this.amount == '') return ElMessage.error('Please enter the amount!')
                const {data: res} = await sellerExtract({
                    money: this.amount
                })
                this.tcshow = false
                this.amount = ''
                if (res.status == 200) {
                    ElMessage.success(res.message)
                    this.getMoneyAmount()
                    this.getWithdrawalRecords()
                } else {
                    ElMessage.error(res.message)
                }
            },
            // 下拉选择事件
            selectChange(){
                for(var i = 0; i < this.selectList.length; i++) {
                    if(this.selectValue == this.selectList[i].value) {
                        this.cryptoAddress = this.selectList[i].address
                    }
                }
            },
            // 上一页
            prevChange(e){
				this.page = e
                this.getWithdrawalRecords()
			},
			// 下一页
			nextChange(e){
				this.page = e
                this.getWithdrawalRecords()
			},
			// 点击分页的数字
			currentChaneg(e){
				this.page = e
                this.getWithdrawalRecords()
			}
        }
    }
</script>
<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
		.pc {
			display: block !important;
		}
		.phone {
			display: none !important;
		}
	}

	/* 竖屏*/
	@media all and (orientation: portrait) {
		.pc {
			display: none !important;
		}
		.phone {
			display: block !important;
		}
	}
    .pc {
        .bigbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .titles {
                width: 100%;
                height: 60px;
                font-size: 18px;
                font-weight: 600;
                text-align: left;
                line-height: 60px; 
            }
            .walletbox {
                width: 100%;
                height: 196px;
                display: flex;
                justify-content: space-between;
                .itemwall {
                    width: 500px;
                    height: 196px;
                    background: #00ADF1;
                    border: 1px solid #DFDFDF;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 60px;
                        height: 60px;
                    }
                    .moneywz {
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        color: #FFFFFF;
                        margin-left: 20px;
                        &>span:nth-child(1){
                            font-size: 30px;
                            font-family: Arial;
                            font-weight: bold;
                        }
                        &>span:nth-child(2){
                            font-size: 14px;
                        }
                    }
                }
                .itemwalls {
                        width: 500px;
                        height: 196px;
                        border: 1px solid #DFDFDF;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        .quanquan {
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            background: #CACACA;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            span {
                                display: inline-block;
                                width: 38px;
                                height: 38px;
                                border-radius: 50%;
                                border: solid 4px #fff;
                            }
                        }
                        .moneywzs {
                            font-size: 18px;
                            margin-left: 20px;
                        }
                        &>span {
                            display: inline-block;
                            width: 60px;
                            height: 60px;
                            background: #CACACA;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 60px;
                            font-size: 48px;
                            font-family: Arial;
                            font-weight: bold;
                            color: #FFFFFF;
                        }
                    }
            }
            .withdbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                .tabtitle {
                    width: 100%;
                    height: 55px;
                    line-height: 55px;
                    text-align: left;
                    font-size: 18px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 1px solid #DFDFDF; 
                }
                .tabbox {
                    text-align: left;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    .span1 {
                        display: inline-block;
                        padding: 0 10px;
                        background: #0ABB75;
                        border-radius: 5px;
                        font-size: 14px;
                        color: #fff;
                        margin: 5px 0px;
                    }
                    .span2 {
                        display: inline-block;
                        padding: 0 10px;
                        background: #FF3030;
                        border-radius: 5px;
                        font-size: 14px;
                        color: #fff;
                        margin: 5px 0px; 
                    }
                    .span3 {
                        display: inline-block;
                        padding: 0 10px;
                        background: #EEAD0E;
                        border-radius: 5px;
                        font-size: 14px;
                        color: #fff;
                        margin: 5px 0px; 
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            .tcbox {
                width: 100%;
                height: 100%;
                position: fixed;
                z-index: 99;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5);
                .centbox {
                    width: 25%;
                    background: #fff;
                    border-radius: 5px;
                    margin: auto;
                    margin-top: 10vh;
                    .titlebox {
                        width: 100%;
                        height: 60px;
                        border-bottom: solid 1px #e6e6e6;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 20px;
                        font-size: 18px;
                        font-weight: 600;
                        img {
                            width: 25px;
                            height: 25px;
                            cursor: pointer;
                        }
                    }
                    .cents {
                        box-sizing: border-box;
                        padding: 0px 20px;
                        .itemcent {
                            font-size: 14px;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 30px;
                            .leftbox {
                                width: 16%;
                                text-align: left;
                                span:nth-child(2){
                                    color: crimson;
                                }
                            }
                            input {
                                width: 70%;
                                height: 50px;
                                box-sizing: border-box;
                                border-radius: 5px;
                                padding-left: 15px;
                                outline: none;
                                border: solid 1px #e6e6e6;
                            }
                            textarea {
                                width: 70%;
                                height: 120px;
                                outline: none;
                                border: solid 1px #e6e6e6;
                                border-radius: 5px;
                                resize: none;
                                box-sizing: border-box;
                                padding: 5px;
                            }
                            :deep(.el-select) {
                            width: 70%;
                            }
                            :deep(.el-input__inner){
                                height: 50px;
                            }
                        }
                    }
                    .sendbox {
                        height: 70px;
                        box-sizing: border-box;
                        padding: 0 20px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        border-top: solid 1px #e6e6e6;
                        margin-top: 20px;
                        span {
                            display: block;
                            width: 90px;
                            height: 50px;
                            border-radius: 5px;
                            background: #00ADF1;
                            color: #fff;
                            text-align: center;
                            line-height: 50px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .phone {
        .bigbox {
            width: 100%;
            box-sizing: border-box;
            padding: 0 25px;
            .titles {
                width: 100%;
                height: 60px;
                font-size: 28px;
                font-weight: 600;
                text-align: left;
                line-height: 80px; 
            }
            .walletbox {
                width: 100%;
                .itemwall {
                    width: 100%;
                    height: 240px;
                    background: #00ADF1;
                    border: 2px solid #DFDFDF;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                    .moneywz {
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        color: #FFFFFF;
                        margin-top: 20px;
                        &>span:nth-child(1){
                            font-size: 40px;
                            font-family: Arial;
                            font-weight: bold;
                        }
                        &>span:nth-child(2){
                            font-size: 24px;
                            opacity: 0.7;
                        }
                    }
                }
                .itemwalls {
                    width: 100%;
                    height: 240px;
                    border: 2px solid #DFDFDF;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    .quanquan {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        background: #CACACA;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 20px;
                        span {
                            display: inline-block;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            border: solid 4px #fff;
                        }
                    }
                    .moneywzs {
                        font-size: 28px;
                    }
                    &>span {
                        display: inline-block;
                        width: 80px;
                        height: 80px;
                        background: #CACACA;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 80px;
                        font-size: 50px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #FFFFFF;
                        margin-bottom: 20px;
                    }
                }
            }
            .withdbox {
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                border-radius: 5px;
                margin-top: 30px;
                .tabtitle {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    text-align: left;
                    font-size: 28px;
                    box-sizing: border-box;
                    padding: 0 30px;
                    border-bottom: 2px solid #DFDFDF; 
                }
                .tabbox {
                    text-align: left;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 30px;
                    p {
                        font-size: 24px;
                    }
                    .span1 {
                        display: inline-block;
                        padding: 5px 10px;
                        background: #0ABB75;
                        border-radius: 5px;
                        font-size: 24px;
                        color: #fff;
                        margin: 5px 0px;
                    }
                    .span2 {
                        display: inline-block;
                        padding: 5px 10px;
                        background: #FF3030;
                        border-radius: 5px;
                        font-size: 24px;
                        color: #fff;
                        margin: 5px 0px;
                    }
                    .span3 {
                        display: inline-block;
                        padding: 5px 10px;
                        background: #EEAD0E;
                        border-radius: 5px;
                        font-size: 24px;
                        color: #fff;
                        margin: 5px 0px;
                    }
                }
            }
            .fenye {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .my-pagination {
                    :deep(.is-active) {
                        width: 50px;
                        height: 50px;
                    }
                    :deep(.number) {
                        font-size: 40px !important;
                    }
                    :deep(.is-first) {
                        width: 50px !important;
                        height: 40px !important;
                    }
                    :deep(.el-icon) {
                        font-size: 40px !important;
                    }
                }
            }
            .tcbox {
                width: 100%;
                height: 100%;
                position: fixed;
                z-index: 99;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5);
                .centbox {
                    width: 60%;
                    background: #fff;
                    border-radius: 5px;
                    margin: auto;
                    margin-top: 10vh;
                    .titlebox {
                        width: 100%;
                        height: 80px;
                        border-bottom: solid 1px #e6e6e6;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 20px;
                        font-size: 28px;
                        font-weight: 600;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    .cents {
                        box-sizing: border-box;
                        padding: 0px 20px;
                        .itemcent {
                            font-size: 24px;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 30px;
                            .leftbox {
                                width: 16%;
                                text-align: left;
                                span:nth-child(2){
                                    color: crimson;
                                }
                            }
                            input {
                                width: 70%;
                                height: 60px;
                                box-sizing: border-box;
                                border-radius: 5px;
                                padding-left: 15px;
                                outline: none;
                                border: solid 2px #e6e6e6;
                                font-size: 24px;
                            }
                            textarea {
                                width: 70%;
                                height: 120px;
                                outline: none;
                                border: solid 1px #e6e6e6;
                                border-radius: 5px;
                                resize: none;
                                box-sizing: border-box;
                                padding: 5px;
                                font-size: 24px;
                            }
                            :deep(.el-select) {
                                width: 70%;
                                font-size: 24px;
                            }
                            :deep(.el-input__inner){
                                height: 60px;
                            }
                        }
                    }
                    .sendbox {
                        height: 80px;
                        box-sizing: border-box;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        border-top: solid 1px #e6e6e6;
                        margin-top: 20px;
                        span {
                            display: block;
                            width: 120px;
                            height: 60px;
                            border-radius: 5px;
                            background: #00ADF1;
                            color: #fff;
                            text-align: center;
                            line-height: 60px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
</style>