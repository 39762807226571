<template>
    <div class="pc">
        <div class="titles">
            {{ $t('button.button.Blogs') }}
        </div>
        <div class="itembox">
            <div class="items">
                <img src="../../assets/img/blog-01.jpg">
                <span class="span1">3 must-buy travel items for new parents</span>
                <span class="span2">Family</span>
                <span class="span3">Whether it's a day outing or a long family holiday, ample preparation always pays off.Besides doing ample research on the transportation</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-02.jpg">
                <span class="span1">Get more with our 10th Anniversary sale guide!</span>
                <span class="span2">On Trend</span>
                <span class="span3">Celebrate our birthday with deals over 50% off, money-saving games, and a trip down memory lane to relive your journey</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-03.jpg">
                <span class="span1">Effective Self Defense Tips for Women</span>
                <span class="span2">Life Hacks</span>
                <span class="span3">The world is not a safe place, especially for our women. Everyday, women from all around the world have to go through unimaginable situations.</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-04.jpg">
                <span class="span1">Stay fashionably chill</span>
                <span class="span2">Style</span>
                <span class="span3">Summer is over and Milan Fashion Week is just around the corner. It’s time to check out what your favorite influencers are wearing on the runway and out on the streets.</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-05.jpg">
                <span class="span1">What’s Trending This Winter Season?</span>
                <span class="span2">Fashion</span>
                <span class="span3">Winter is THE season for fashion. We think it’s the winter fashion is one of the most stylish and attractive one. It’s got a rather cosy but fancy touch to it that we simply love.</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-06.jpg">
                <span class="span1">T-Shirts Every Man Needs in His Wardrobe</span>
                <span class="span2">Man Fashion</span>
                <span class="span3">Fashion is for men just as much as it is for women. And whoever says that men can get away with just about any shirt with jeans – ah,they couldn’t be more wrong!</span>
            </div>
        </div>
    </div>
    <div class="phone">
        <div class="titles">
            {{ $t('button.button.Blogs') }}
        </div>
        <div class="itembox">
            <div class="items">
                <img src="../../assets/img/blog-01.jpg">
                <span class="span1">3 must-buy travel items for new parents</span>
                <span class="span2">Family</span>
                <span class="span3">Whether it's a day outing or a long family holiday, ample preparation always pays off.Besides doing ample research on the transportation</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-02.jpg">
                <span class="span1">Get more with our 10th Anniversary sale guide!</span>
                <span class="span2">On Trend</span>
                <span class="span3">Celebrate our birthday with deals over 50% off, money-saving games, and a trip down memory lane to relive your journey</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-03.jpg">
                <span class="span1">Effective Self Defense Tips for Women</span>
                <span class="span2">Life Hacks</span>
                <span class="span3">The world is not a safe place, especially for our women. Everyday, women from all around the world have to go through unimaginable situations.</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-04.jpg">
                <span class="span1">Stay fashionably chill</span>
                <span class="span2">Style</span>
                <span class="span3">Summer is over and Milan Fashion Week is just around the corner. It’s time to check out what your favorite influencers are wearing on the runway and out on the streets.</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-05.jpg">
                <span class="span1">What’s Trending This Winter Season?</span>
                <span class="span2">Fashion</span>
                <span class="span3">Winter is THE season for fashion. We think it’s the winter fashion is one of the most stylish and attractive one. It’s got a rather cosy but fancy touch to it that we simply love.</span>
            </div>
            <div class="items">
                <img src="../../assets/img/blog-06.jpg">
                <span class="span1">T-Shirts Every Man Needs in His Wardrobe</span>
                <span class="span2">Man Fashion</span>
                <span class="span3">Fashion is for men just as much as it is for women. And whoever says that men can get away with just about any shirt with jeans – ah,they couldn’t be more wrong!</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation: portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }
    .pc {
        width: 1380px;
        margin: auto;
        .titles {
            width: 100%;
            height: 100px;
            line-height: 100px;
            text-align: left;
            font-size: 24px;
            font-family: Arial;
        }
        .itembox {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .items {
                width: 445px;
                background: #FFFFFF;
                border: 1px solid #FFFFFF;
                border-radius: 4px;
                margin-bottom: 20px;
                padding-bottom: 20px;
                img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 10px;
                }
                .span1{
                    display: block;
                    width: 400px;
                    margin: auto;
                    text-align: left;
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: bold;
                }
                .span2{
                    display: block;
                    width: 400px;
                    margin: auto;
                    text-align: left;
                    font-size: 14px;
                    font-family: Arial;
                    color: #969696;
                    font-style: italic;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                .span3{
                    display: block;
                    width: 400px;
                    margin: auto;
                    text-align: left;
                    font-size: 14px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #969696;
                    line-height: 18px;
                }
            }
        }
    }
    .phone {
        width: 100%;
        margin: auto;
        .titles {
            width: 100%;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 32px;
            font-family: Arial;
        }
        .itembox {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .items {
                width: 30%;
                background: #FFFFFF;
                border: 1px solid #FFFFFF;
                border-radius: 4px;
                margin-bottom: 20px;
                padding-bottom: 20px;
                img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 10px;
                }
                .span1{
                    display: block;
                    width:96%;
                    margin: auto;
                    text-align: left;
                    font-size: 26px;
                    font-family: Arial;
                    font-weight: bold;
                }
                .span2{
                    display: block;
                    width: 96%;
                    margin: auto;
                    text-align: left;
                    font-size: 20px;
                    font-family: Arial;
                    color: #969696;
                    font-style: italic;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
                .span3{
                    display: block;
                    width: 96%;
                    margin: auto;
                    text-align: left;
                    font-size: 20px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #969696;
                    line-height: 18px;
                }
            }
        }
    }
</style>