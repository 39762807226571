<template>
    <div class="pc">
        <div class="bigbox">
            <div class="wancbox">
                <div class="imgbox">
                    <img src="../../../assets/img/wancheng-lan.png">
                    <span class="span1">Thank You for Your Order!</span>
                    <span class="span2">A copy or your order summary has been sent to {{ orderInfo.email }}</span>
                </div>
            </div>
            <div class="centbox">
                <div class="ordersum">
                    <span>{{ $t('buyer.orderDetail.orderSummary') }}</span>
                </div>
                <div class="twobox">
                    <div class="itemone">
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.date') }}:</span>
                            <span>{{ orderInfo.order_date }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.manageProfile.fullName') }}:</span>
                            <span>{{ orderInfo.name }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.manageProfile.email') }}:</span>
                            <span>{{ orderInfo.email }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.address') }}</span>
                            <span>{{ orderInfo.address }}</span>
                        </div>
                    </div>
                    <div class="itemone">
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.orderStatus') }}:</span>
                            <span>{{ orderInfo.order_status }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.orderAmount') }}:</span>
                            <span>{{ orderInfo.total_order_amount }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.shipping') }}:</span>
                            <span>{{ orderInfo.shipping }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.paymentMethod') }}:</span>
                            <span>{{ orderInfo.payment_method }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="item in orderInfo.list" :key="item.id">
                <div class="ordercode">
                    <div class="codebox">
                        <span>{{ $t('buyer.orderDetail.orderCode') }}</span>
                        <span>{{ item.order_no }}</span>
                    </div>
                    <div class="detailbox">
                        <span>{{ $t('buyer.orderDetail.orderDetail') }}</span>
                    </div>
                    <div class="tabbox">
                        <el-table :data="item.orderGoods" style="width: 100%">
                            <el-table-column type="index" width="50" label="#" />
                            <el-table-column prop="goods_name" :label="$t('buyer.category.products')" width="500" />
                            <el-table-column prop="variation" label="Variation" width="150" />
                            <el-table-column prop="count" :label="$t('buyer.orderDetail.quantity')" width="150"/>
                            <el-table-column prop="delivery_type" :label="$t('buyer.orderDetail.deliveryType')" />
                            <el-table-column prop="total_price" :label="$t('buyer.orderDetail.price')"/>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 手机端 -->
    <div class="phone">
        <div class="bigbox">
            <div class="wancbox">
                <div class="imgbox">
                    <img src="../../../assets/img/wancheng-lan.png">
                    <span class="span1">Thank You for Your Order!</span>
                    <span class="span2">A copy or your order summary has been sent to {{ orderInfo.email }}</span>
                </div>
            </div>
            <div class="centbox">
                <div class="ordersum">
                    <span>{{ $t('buyer.orderDetail.orderSummary') }}</span>
                </div>
                <div class="twobox">
                    <div class="itemone">
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.date') }}:</span>
                            <span>{{ orderInfo.order_date }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.manageProfile.fullName') }}:</span>
                            <span>{{ orderInfo.name }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.manageProfile.email') }}:</span>
                            <span>{{ orderInfo.email }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.address') }}</span>
                            <span>{{ orderInfo.address }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.orderStatus') }}:</span>
                            <span>{{ orderInfo.order_status }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.orderAmount') }}:</span>
                            <span>{{ orderInfo.total_order_amount }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.shipping') }}:</span>
                            <span>{{ orderInfo.shipping }}</span>
                        </div>
                        <div class="items">
                            <span>{{ $t('buyer.orderDetail.paymentMethod') }}:</span>
                            <span>{{ orderInfo.payment_method }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="item in orderInfo.list" :key="item.id">
                <div class="ordercode">
                    <div class="codebox">
                        <span>{{ $t('buyer.orderDetail.orderCode') }}</span>
                        <span>{{ item.order_no }}</span>
                    </div>
                    <div class="detailbox">
                        <span>{{ $t('buyer.orderDetail.orderDetail') }}</span>
                    </div>
                    <div class="tabbox">
                        <el-table :data="item.orderGoods" style="width: 100%" :header-cell-style="{fontSize:'24px'}">
                            <el-table-column prop="goods_name" :label="$t('buyer.category.products')" width="360">
                                <template #default="scope">
                                    <span>{{ scope.row.goods_name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="variation" label="Variation" width="150">
                                <template #default="scope">
                                    <span>{{ scope.row.variation }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="count" :label="$t('buyer.orderDetail.quantity')" width="140">
                                <template #default="scope">
                                    <span>{{ scope.row.count }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="delivery_type" :label="$t('buyer.orderDetail.deliveryType')" width="200">
                                <template #default="scope">
                                    <span>{{ scope.row.delivery_type }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="total_price" :label="$t('buyer.orderDetail.price')">
                                <template #default="scope">
                                    <span>{{ scope.row.total_price }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {orderConfirmed} from "@/api/buyerapi"
    import { ElMessage } from 'element-plus'
    export default {
        data(){
            return {
                orderId: '', //订单id
                orderInfo: [],
            }
        },
        mounted(){
            this.orderId = this.$route.query.order_id
            this.getOrder()
        },
        methods: {
            async getOrder(){
                const {data: res} = await orderConfirmed({
                    order_id_str: this.orderId
                })
                if(res.status == 200) {
                    this.orderInfo = res.success
                    console.log(res)
                } else {
                    ElMessage.error(res.message)
                }
            }
        }
    }
</script>

<style lang="less" scoped>

    // 横屏
    @media all and (orientation: landscape) {
        .pc {
            display: block !important;
        }
        .phone {
            display: none !important;
        }
    }

    /* 竖屏*/
    @media all and (orientation : portrait) {
        .pc {
            display: none !important;
        }
        .phone {
            display: block !important;
        }
    }
    .pc {
        .bigbox {
            width: 1380px;
            margin: auto;
            .wancbox {
                width: 100%;
                margin-top: 100px;
                .imgbox {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                    .span1 {
                        font-size: 24px;
                        margin-top: 25px;
                    }
                    .span2{
                        font-size: 16px;
                        color: #969696;
                        margin-top: 15px;
                    }
                }
            }
            .centbox {
                width: 100%;
                background: #fff;
                margin-top: 60px;
                .ordersum {
                    width: 100%;
                    height: 60px;
                    border-bottom: solid 1px #cacaca;
                    font-size: 18px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #000000;
                    text-align: left;
                    padding: 0 30px;
                    line-height: 80px;
                    box-sizing: border-box;
                }
                .twobox {
                    box-sizing: border-box;
                    padding: 0 30px;
                    display: flex;
                    justify-content: space-between;
                    .itemone {
                        width: 580px;
                        .items {
                            width: 100%;
                            min-height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 14px;
                            border-bottom: solid 1px #cacaca;
                            span:nth-child(2){
                                display: inline-block;
                                width: 380px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .ordercode {
                width: 100%;
                background: #fff;
                margin-top: 30px;
                .codebox {
                    font-size: 18px;
                    height: 80px;
                    line-height: 80px;
                    span:nth-child(2){
                        color: #00ADF1;
                        margin-left: 10px;
                    }
                }
                .detailbox {
                    font-size: 18px;
                    font-family: Arial;
                    text-align: left;
                    height: 50px;
                    line-height: 50px;
                    padding: 0px 30px;
                    box-sizing: border-box;
                    border-bottom: solid 1px #cacaca;
                }
            }
        }
    }
    .phone {
        .bigbox {
            width: 100%;
            margin: auto;
            .wancbox {
                width: 100%;
                margin-top: 100px;
                .imgbox {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img {
                        width: 100px;
                        height: 100px;
                    }
                    .span1 {
                        font-size: 34px;
                        margin-top: 25px;
                    }
                    .span2{
                        font-size: 26px;
                        color: #969696;
                        margin-top: 15px;
                    }
                }
            }
            .centbox {
                width: 100%;
                background: #fff;
                margin-top: 60px;
                .ordersum {
                    width: 100%;
                    height: 100px;
                    border-bottom: solid 1px #cacaca;
                    font-size: 28px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #000000;
                    text-align: left;
                    padding: 0 30px;
                    line-height: 100px;
                    box-sizing: border-box;
                }
                .twobox {
                    box-sizing: border-box;
                    padding: 0 30px;
                    display: flex;
                    justify-content: space-between;
                    .itemone {
                        width: 100%;
                        .items {
                            width: 100%;
                            min-height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 24px;
                            border-bottom: solid 1px #cacaca;
                            span:nth-child(2){
                                display: inline-block;
                                width: 75%;
                                text-align: left;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
            .ordercode {
                width: 100%;
                background: #fff;
                margin-top: 30px;
                .codebox {
                    font-size: 28px;
                    height: 80px;
                    line-height: 80px;
                    span:nth-child(2){
                        color: #00ADF1;
                        margin-left: 10px;
                    }
                }
                .detailbox {
                    font-size: 28px;
                    font-family: Arial;
                    text-align: left;
                    height: 50px;
                    line-height: 50px;
                    padding: 0px 30px;
                    box-sizing: border-box;
                    border-bottom: solid 2px #cacaca;
                }
                .tabbox {
                    margin-top: 10px;
                    span {
                        font-size: 24px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
</style>